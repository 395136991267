import { FC } from 'react';

interface LoadingModalProps {}

const LoadingModal: FC<LoadingModalProps> = () => {
  return (
    <>
      <div className='fixed top-0 left-0 z-30 h-full w-full bg-grey opacity-80'></div>
      <div className='fixed top-4 left-0 z-50 flex flex-col content-center items-center bg-white p-20 text-center shadow-2xl sm:left-auto sm:top-28 sm:mx-auto sm:w-[80vw] sm:px-24 md:w-[70vw] lg:w-[50vw]'>
        <div className='mx-auto h-10 w-10 animate-spin rounded-full border-2 border-solid border-light-grey border-t-[transparent] border-l-[transparent]'></div>
        <p className='mt-8 text-lg font-light'>Einen Moment bitte, wir ermitteln den Wert der Immobilie...</p>
      </div>
    </>
  );
};

export default LoadingModal;
