import TagManager from 'react-gtm-module';

export const pushToDataLayer = (trackingObject: any) => {
  TagManager.dataLayer({ dataLayer: { ...trackingObject } });
};

// export const trackLink = (eventLabel: string, eventCategory: string) => {
//   TagManager.dataLayer({ dataLayer: { event: 'Click', eventAction: 'Link', eventLabel, eventCategory } });
// };
// export const trackClick = (eventType: string, eventCategory: string, eventValue: string) => {
//   TagManager.dataLayer({ dataLayer: { event: 'Click', eventAction: 'Click', eventType, eventCategory, eventValue } });
// };
// export const trackSubmit = (eventCategory: string, eventValue: string) => {
//   TagManager.dataLayer({ dataLayer: { event: 'Click', eventAction: 'Submit', eventCategory, eventValue } });
// };

export const separateNumberWithPoints = (number: number): string => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

export const categorizeAndStringifyValuationResult = (result: number, { min, max, step }: Range): string => {
  if (result <= min) return `<= ${separateNumberWithPoints(min)} €`;
  if (result >= max) return `>= ${separateNumberWithPoints(max)} €`;

  for (let currentStepIndex = 0; currentStepIndex < max / step; currentStepIndex += 1) {
    if (result < min + 1 + step * currentStepIndex) {
      const lower = min + 1 + step * currentStepIndex - step;
      const higher = min + step * currentStepIndex;
      return `${separateNumberWithPoints(lower)} - ${separateNumberWithPoints(higher)} €`;
    }
    if (result > max - step && result < max) {
      const lower = max - step + 1;
      const higher = max - 1;
      return `${separateNumberWithPoints(lower)} - ${separateNumberWithPoints(higher)} €`;
    }
  }

  return `${separateNumberWithPoints(result)} €`;
};

export class TrackingObjectBuilder {
  trackingObject = {};

  forClick({ eventLabel, eventCategory }: { eventLabel: string; eventCategory: string }) {
    this.trackingObject = {
      event: 'Click',
      eventAction: 'Click',
      eventLabel,
      eventCategory,
    };
    return this;
  }

  forFieldSubmit({
    eventAction,
    eventCategory,
    eventLabel,
    eventValue,
  }: {
    eventAction: string;
    eventCategory: string;
    eventLabel: string;
    eventValue: number;
  }) {
    this.trackingObject = {
      event: 'form',
      eventType: 'submit',
      eventCategory,
      eventAction,
      eventLabel,
      eventValue,
    };
    return this;
  }

  build() {
    return this.trackingObject;
  }
}

interface Range {
  min: number;
  max: number;
  step: number;
}

export const calculateRange = (result: number, { min, max, step }: Range): string => {
  if (result <= min) return `<= ${min}`;
  if (result >= max) return `>= ${max}`;

  for (let currentStepIndex = 0; currentStepIndex < max / step; currentStepIndex += 1) {
    if (result < min + 1 + step * currentStepIndex) {
      const lower = min + 1 + step * currentStepIndex - step;
      const higher = min + step * currentStepIndex;
      return `${lower} - ${higher}`;
    }
    if (result > max - step && result < max) {
      const lower = max - step + 1;
      const higher = max - 1;
      return `${lower} - ${higher}`;
    }
  }

  return `${min} - ${max}`;
};
