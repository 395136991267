import { AppState } from 'models';
import { atom, selector } from 'recoil';
import { isDevelopment } from 'utils';
import { developmentDefault, productionDefault } from './default-state';

export const appStateAtom = atom<AppState>({
  key: 'appState',
  default: isDevelopment ? developmentDefault : productionDefault,
});

export const isUserLoggedInSelector = selector({
  key: 'isUserLoggedIn',
  get: ({ get }) => !!get(appStateAtom).user?.valuations?.length,
});
