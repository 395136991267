import axios from 'axios';
import { RbiHeading, RbiLink, LinkType, RbiError, RbiInput, RbiButton, RbiCheckbox } from 'components';
import { RbiToggle } from 'components/rbi-toggle';
import { PropertyTypes, WizardStepsTypes } from 'enums';
import { User, HttpErrorBody } from 'models';
import { RecaptchaResponse } from 'models/recaptcha.model';
import { FC, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { appStateAtom } from 'state';
import { apiUrls, conditionToString, dataProtectionUrl, emailRegex, equipmentToString, usageToString } from 'utils';
import { calculateRange, pushToDataLayer, TrackingObjectBuilder } from 'utils/tag-manager';
import LoadingModal from './loading-modal';

export const WAITING_TIME_VALUATION = 2000;
export const RECAPTCHA_THRESHOLD = 0.5;

export const ContactData: FC = () => {
  useEffect(() => window.scrollTo(0, 0), []);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [wizardData, setWizardData] = useRecoilState(appStateAtom);

  const { register, getValues, control, formState, handleSubmit } = useForm<User>({
    mode: 'onChange',
    defaultValues: { ...wizardData.user, consentedMarketingPurposes: !!wizardData.user?.consentedMarketingPurposes },
  });

  const submitForm = async (user: User) => {
    sendDataToGTM();
    setIsLoading(true);
    preventScrolling();
    setWizardData((state) => ({ ...state, user }));
    grecaptcha.ready(async () => {
      if (!process.env.REACT_APP_RECAPCHA_KEY) {
        setWizardData((state) => ({ ...state, httpError: { message: 'No reCaptcha key', status: '0' } }));
        navigate('/server-error');
      } else {
        try {
          const token = await grecaptcha.execute(process.env.REACT_APP_RECAPCHA_KEY, { action: 'submitValuation' });
          const recaptchaResponse = (await axios.post<RecaptchaResponse>(apiUrls.recaptcha, { token })).data;
          if (!recaptchaResponse.success) {
            setWizardData((state) => ({ ...state, httpError: { message: 'reCaptcha Failed', status: '201' } }));
            navigate('/server-error');
          }
          if (recaptchaResponse.score < RECAPTCHA_THRESHOLD) {
            navigate('/onboarding');
          }
          await axios.post(apiUrls.valuations, { ...wizardData, user });
          preventScrolling(false);
          setIsLoading(false);
          navigate('/confirmation');
        } catch (error: any) {
          console.log(error);
          wait(() => {
            setIsLoading(false);
            preventScrolling(false);
            const httpError = error?.response?.data as HttpErrorBody;
            setWizardData((state) => ({ ...state, httpError }));
            navigate('/server-error');
          });
        }
      }
    });
  };

  const sendDataToGTM = () => {
    const areaRange = { min: 50, max: 250, step: 10 };
    switch (wizardData.propertyType) {
      case PropertyTypes.Apartment:
        if (wizardData.propertyDetails?.apartmentDetails) {
          const { area, floor, condition, constructionYear, equipment } = wizardData.propertyDetails.apartmentDetails;
          pushToDataLayer(
            new TrackingObjectBuilder()
              .forFieldSubmit({ eventCategory: 'ProjectDetails', eventAction: 'Year of Construction', eventLabel: `${constructionYear}`, eventValue: 0 })
              .build(),
          );
          pushToDataLayer(
            new TrackingObjectBuilder()
              .forFieldSubmit({ eventCategory: 'ProjectDetails', eventAction: 'm2', eventLabel: calculateRange(area, areaRange), eventValue: area })
              .build(),
          );
          pushToDataLayer(
            new TrackingObjectBuilder()
              .forFieldSubmit({ eventCategory: 'ProjectDetails', eventAction: 'Condition', eventLabel: conditionToString(condition), eventValue: 0 })
              .build(),
          );
          equipment.forEach((item) => {
            pushToDataLayer(
              new TrackingObjectBuilder()
                .forFieldSubmit({ eventCategory: 'ProjectDetails', eventAction: 'Equipment', eventLabel: `${equipmentToString(item)}`, eventValue: 0 })
                .build(),
            );
          });
        }
        break;
      case PropertyTypes.Duplex:
      case PropertyTypes.FamilyHouse:
      case PropertyTypes.TerracedHouse:
        if (wizardData.propertyDetails?.houseDetails) {
          const { area, plotArea, condition, constructionYear, equipment } = wizardData.propertyDetails.houseDetails;
          pushToDataLayer(
            new TrackingObjectBuilder()
              .forFieldSubmit({ eventCategory: 'ProjectDetails', eventAction: 'Year of Construction', eventLabel: `${constructionYear}`, eventValue: 0 })
              .build(),
          );
          pushToDataLayer(
            new TrackingObjectBuilder()
              .forFieldSubmit({ eventCategory: 'ProjectDetails', eventAction: 'm2', eventLabel: calculateRange(area, areaRange), eventValue: area })
              .build(),
          );
          pushToDataLayer(
            new TrackingObjectBuilder()
              .forFieldSubmit({ eventAction: 'Land Area', eventCategory: 'ProjectDetails', eventLabel: `${plotArea}`, eventValue: plotArea })
              .build(),
          );
          pushToDataLayer(
            new TrackingObjectBuilder()
              .forFieldSubmit({ eventCategory: 'ProjectDetails', eventAction: 'Condition', eventLabel: conditionToString(condition), eventValue: 0 })
              .build(),
          );
          equipment.forEach((item) => {
            pushToDataLayer(
              new TrackingObjectBuilder()
                .forFieldSubmit({ eventCategory: 'ProjectDetails', eventAction: 'Equipment', eventLabel: `${equipmentToString(item)}`, eventValue: 0 })
                .build(),
            );
          });
        }
        break;
      case PropertyTypes.Plot:
        if (wizardData.propertyDetails?.plotDetails) {
          const { plotArea, usage } = wizardData.propertyDetails.plotDetails;
          pushToDataLayer(
            new TrackingObjectBuilder()
              .forFieldSubmit({ eventAction: 'Land Area', eventCategory: 'ProjectDetails', eventLabel: `${plotArea}`, eventValue: plotArea })
              .build(),
          );
          pushToDataLayer(
            new TrackingObjectBuilder()
              .forFieldSubmit({ eventCategory: 'ProjectDetails', eventAction: 'Usage', eventLabel: usageToString(usage), eventValue: 0 })
              .build(),
          );
        }
    }
  };

  const wait = (callback: () => any) => setTimeout(callback, WAITING_TIME_VALUATION);
  const preventScrolling = (prevent = true) => (document.body.style.overflow = prevent ? 'hidden' : '');

  return (
    <div>
      <RbiHeading variant={4}>Fast fertig: Sie müssen nur noch Ihre Kontaktdaten angeben.</RbiHeading>
      <p className='mb-6 text-sm'>
        Die Daten zu Ihrer Traumimmobilie sind nun komplett. Jetzt fehlen nur noch Ihre Kontaktdaten, damit wir Ihnen das Ergebnis Ihres ImmoChecks per E-Mail
        zukommen lassen können.
      </p>
      <p className='mb-6 text-sm'>
        Nähere Informationen zum Datenschutz erhalten Sie{' '}
        <RbiLink variant={LinkType.ExternalSmall} url={dataProtectionUrl}>
          hier
        </RbiLink>
        .
      </p>

      {/* GENDER */}
      <Controller
        control={control}
        name='gender'
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <RbiToggle hasErrors={!!formState.errors.gender} defaultValue={value} onChange={onChange} option1='Frau' option2='Herr'></RbiToggle>
        )}
      />
      {!!formState.errors.gender && <RbiError>Bitte wählen Sie ihr Geschlecht.</RbiError>}
      {/* END GENDER */}

      {/* NAME */}
      <RbiInput
        register={register('firstName', { required: true })}
        value={getValues('firstName')}
        hasErrors={!!formState.errors.firstName}
        placeholder='Vorname *'
        cssClasses='mt-4'
      ></RbiInput>
      <RbiInput
        register={register('lastName', { required: true })}
        value={getValues('lastName')}
        hasErrors={!!formState.errors.lastName}
        placeholder='Nachname *'
        cssClasses='mt-4'
      ></RbiInput>
      {(!!formState.errors.firstName || !!formState.errors.lastName) && <RbiError>Bitte tragen Sie Ihren Vor- und Nachnamen ein.</RbiError>}
      {/* END NAME */}

      {/* EMAIL */}
      <RbiInput
        register={register('email', { required: true, pattern: emailRegex })}
        type='email'
        value={getValues('email')}
        hasErrors={!!formState.errors.email}
        placeholder='E-Mail-Adresse *'
        cssClasses='mt-4'
      ></RbiInput>
      {!!formState.errors.email && <RbiError>Bitte überprüfen Sie Ihre E-Mail-Adresse.</RbiError>}
      {/* END EMAIL */}

      {/* TERMS OF USE */}
      <div className='mt-8'>
        <Controller
          control={control}
          rules={{ required: true }}
          name='consentedTermsOfUse'
          render={({ field: { value, onChange } }) => (
            <RbiCheckbox isSelected={value} onSelect={onChange}>
              <span className='w-auto'>
                Ich stimme den für die Verwendung des ImmoChecks geltenden{' '}
                <RbiLink
                  variant={LinkType.InternalSmall}
                  url={'/terms-of-use'}
                  onClick={() => {
                    return setWizardData((state) => ({ ...state, user: { ...getValues() } }));
                  }}
                >
                  Nutzungs­bedingungen
                </RbiLink>{' '}
                zu.*
              </span>
            </RbiCheckbox>
          )}
        />
      </div>

      <div className='mt-8'>
        <Controller
          control={control}
          name='consentedMarketingPurposes'
          defaultValue={false}
          render={({ field: { value, onChange } }) => (
            <RbiCheckbox isSelected={value} onSelect={onChange}>
              <div className='w-auto sm:text-justify'>
                Ich bin damit einverstanden, dass die Raiffeisenbank meine hier eingegebenen Daten (Name, E-Mail-Adresse, Objektdaten) zu Marketingzwecken
                automationsunterstützt verarbeiten, an die Raiffeisen-Bankengruppe Steiermark und die Raiffeisen-Werbung Steiermark übermitteln und mich im
                Zusammenhang damit künftig mit Informationen über eigene Angebote, Produkte und Dienstleistungen per E-Mail kontaktieren darf. Diese Zustimmung
                kann ich jederzeit widerrufen. Der Widerruf kann postalisch oder elektronisch an info@raiffeisen.at erfolgen. Weitere Informationen zum
                Datenschutz finde ich{' '}
                <RbiLink variant={LinkType.ExternalSmall} url={'https://www.raiffeisen.at/stmk/de/meine-bank/raiffeisen-bankengruppe/datenschutz.html'}>
                  hier
                </RbiLink>{' '}
                .
              </div>
            </RbiCheckbox>
          )}
        />
      </div>
      {!!formState.errors.consentedTermsOfUse && <RbiError>Bitte stimmen Sie den Nutzungsbedingungen zu.</RbiError>}
      {/* END TERMS OF USE */}

      <div className='my-10 text-center text-sm text-light-grey'>Felder mit * sind Pflichtfelder</div>
      {isLoading && <LoadingModal></LoadingModal>}
      <div className='mt-4 flex justify-around'>
        <RbiButton secondary onClick={() => setWizardData((state) => ({ ...state, user: { ...getValues() }, currentStep: WizardStepsTypes.Overview }))}>
          ZURÜCK
        </RbiButton>
        <div className='w-20'></div>
        <RbiButton onClick={handleSubmit(submitForm)}>WEITER</RbiButton>
      </div>
    </div>
  );
};
