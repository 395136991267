import { FC, Fragment, useEffect } from 'react';
import { onboardingSteps } from './onboarding-steps';
import cx from 'classnames';
import { ReactComponent as Separator } from 'assets/icons/separator.svg';
import { RbiLink, RbiHeading } from 'components';
import { useSearchParams } from 'react-router-dom';
import { pushToDataLayer, TrackingObjectBuilder } from 'utils/tag-manager';

interface OnboardingProps {}

const Onboarding: FC<OnboardingProps> = () => {
  let [searchParams] = useSearchParams();
  const blz = searchParams.get('blz') || '38888';
  useEffect(() => window.scrollTo(0, 0), []);
  return (
    <div>
      <div>
        <div className='relative flex h-[40vh] flex-col items-start justify-center px-10 text-white before:absolute before:top-0 before:left-0 before:-z-10 before:h-full before:w-full before:bg-[url("/src/assets/img/hero.jpg")] before:bg-cover before:bg-center before:bg-no-repeat before:brightness-75 sm:h-[50vh] lg:h-[80vh] lg:px-60'>
          <h1 className='mb-8 text-2xl font-bold drop-shadow-2xl sm:text-5xl'>SCHNELL UND UNKOMPLIZIERT ZUM SCHÄTZWERT IHRER IMMOBILIE.</h1>
          <RbiLink
            url='wizard'
            onClick={() =>
              pushToDataLayer(
                new TrackingObjectBuilder()
                  .forClick({
                    eventLabel: 'JETZT IMMOCHECK STARTEN',
                    eventCategory: 'button',
                  })
                  .build(),
              )
            }
            queryParams={`?blz=${blz}`}
            cssClasses='mx-auto md:mx-0'
          >
            JETZT IMMOCHECK STARTEN
          </RbiLink>
        </div>
      </div>
      <div className='mx-4 bg-white p-10 text-center sm:mx-16 lg:mx-20 lg:p-20 xl:mx-60'>
        <RbiHeading variant={4} cssClasses='mb-14'>
          In nur drei Schritten zum Schätzwert Ihres WohnTraums
        </RbiHeading>
        {onboardingSteps.map(({ icon, title, description }, index) => (
          <Fragment key={index}>
            {icon}
            <RbiHeading variant={4}>{title}</RbiHeading>
            <p className={cx('xl:px-30 px-2 text-sm lg:px-28', { 'mb-16': index !== onboardingSteps.length - 1 })}>{description}</p>
          </Fragment>
        ))}
      </div>
      {/* <Separator className='mx-auto mb-20 w-16 lg:w-20'></Separator> */}
      {/* <Separator className='mx-auto mb-20 w-16 lg:w-20'></Separator> */}
      {/* <div className='mt-14 pb-20 text-center'>
        <RbiHeading variant={4}>In Bestzeit zum ImmoCheck</RbiHeading>
        <p className='mb-14 px-10 text-sm sm:px-20 lg:px-80'>
          Mit dem ImmoCheck der Raiffeisen-Bankengruppe Steiermark ermitteln Sie schnell und unkompliziert den Schätzwert Ihrer Wunschimmobilie.
        </p>
        <RbiLink
          url='wizard'
          queryParams={`?blz=${blz}`}
          onClick={() =>
            pushToDataLayer(
              new TrackingObjectBuilder()
                .forClick({
                  eventLabel: 'JETZT IMMOCHECK STARTEN',
                  eventCategory: 'button',
                })
                .build(),
            )
          }
        >
          JETZT IMMOCHECK STARTEN
        </RbiLink>
      </div> */}
    </div>
  );
};

export default Onboarding;
