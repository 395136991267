import { EquipmentTypes, UsageTypes, ConditionTypes, PropertyTypes } from 'enums';
import { Valuation } from 'models/valuation.interface';
import { conditionOptions, equipmentOptions, usageOptions } from './default-options';

export const propertyTypeToString = (propertyType?: PropertyTypes): string => {
  switch (propertyType) {
    case PropertyTypes.Apartment:
      return 'Wohnung';
    case PropertyTypes.FamilyHouse:
      return 'Einfamilienhaus';
    case PropertyTypes.Duplex:
      return 'Doppelhaushälfte';
    case PropertyTypes.TerracedHouse:
      return 'Reihenhaus';
    case PropertyTypes.Plot:
      return 'Grundstück';
    default:
      return '';
  }
};

export const conditionToString = (condition: ConditionTypes): string => {
  const [, value] = conditionOptions.find(([key]) => key === `${condition}`) || [];
  return value || '';
};

export const equipmentToString = (equipment: EquipmentTypes) => {
  return equipmentOptions.find(([key]) => key === equipment);
};

export const equipmentsToString = (equipments: EquipmentTypes[] = []) => {
  return equipments?.reduce((acc, equipment, index) => {
    const [, equipmentName] = equipmentOptions.find(([key]) => key === equipment) || [];
    return `${equipmentName}${index ? ',' : ''} ${acc}`;
  }, '');
};

export const usageToString = (usage: UsageTypes): string => {
  const [, value] = usageOptions.find(([key]) => key === `${usage}`) || [];
  return value || '';
};

export const valuationToAddress = ({ streetName, houseNumber, postalCode, city }: Valuation) => [`${streetName} ${houseNumber}`, `${postalCode} ${city}`];
