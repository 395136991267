import { LinkType, RbiButton, RbiLink } from 'components';
import { User } from 'models';
import { Valuation } from 'models/valuation.interface';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { appStateAtom } from 'state';
import useSWR from 'swr';
import { apiUrls } from 'utils';
import { ValuationCard } from './valuation-card';
import { cloneDeep } from 'lodash-es';
import { WizardStepsTypes } from 'enums';

export const ValuationsOverview: FC = () => {
  const navigate = useNavigate();
  useEffect(() => window.scrollTo(0, 0), []);
  const [wizardData, setWizardData] = useRecoilState(appStateAtom);
  const [searchParams] = useSearchParams();
  const [orderedValuations, setOrderedValuations] = useState<Valuation[]>();
  const { data: user } = useSWR<User>(`${apiUrls.users}/${searchParams.get('id')}`);
  const canValuate = (user: User | undefined): boolean => !!user?.valuationsLeftToday && !!user?.valuationsLeftThisMonth;
  useEffect(() => {
    const getNumericDate = (valuation: Valuation) => new Date(`${valuation.createdAt}`).getTime();
    if (user?.valuations) {
      setOrderedValuations(cloneDeep(user?.valuations).sort((a, b) => getNumericDate(b) - getNumericDate(a)));
    }
    setWizardData((state) => ({ ...state, user, currentStep: WizardStepsTypes.PropertySelection }));
  }, [user, setOrderedValuations, setWizardData]);

  return (
    <>
      <div className='flex h-[5vh] flex-col items-start justify-center px-10 text-white before:absolute before:top-0 before:left-0 before:-z-10 before:h-[80vh] before:w-full before:bg-[url("/src/assets/img/hero.jpg")] before:bg-cover before:bg-bottom before:bg-no-repeat before:brightness-75 lg:h-[10vh] lg:px-60'></div>
      <div className='mx-auto mb-20 w-[90vw] sm:w-[85vw] lg:w-[80vw]'>
        <div className='bg-white p-5 sm:p-8'>
          <div className='lg: flex flex-col items-center justify-between lg:flex-row'>
            <div className='pb-6 text-center lg:pb-0 lg:text-left'>
              <h2 className='text-lg font-bold sm:text-2xl lg:text-3xl'>IHRE IMMOCHECK ERGEBNISSE:</h2>
              <p className='text-sm sm:text-base'>
                Verbleibende ImmoChecks heute: <span className='font-semibold'>{user?.valuationsLeftToday}</span>
              </p>
            </div>
            <RbiButton
              onClick={() => {
                return navigate('/wizard');
              }}
              disabled={!canValuate(user)}
            >
              NEUE BEWERTUNG STARTEN
            </RbiButton>
          </div>
        </div>
        {orderedValuations?.map((valuation, index) => (
          <ValuationCard valuation={valuation} key={index}></ValuationCard>
        ))}
        <div className='border-b-[1px] border-lighter-grey py-14'>
          Die angegebenen Schätzwerte basieren auf aktuellen Marktdaten. Abweichungen innerhalb der Preisspanne können jedoch nicht ausgeschlossen werden. Mehr
          Informationen zur Bewertung finden sie{' '}
          <RbiLink variant={LinkType.InternalSmall} url={'/terms-of-use'}>
            hier
          </RbiLink>
          .
        </div>
      </div>
    </>
  );
};
