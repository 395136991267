import { Route, Routes, useSearchParams } from 'react-router-dom';
import Onboarding from './screens/onboarding/onboarding';
import Wizard from './screens/wizard/wizard';
import { RbiHeader, RbiFooter } from 'components';
import TermsOfUse from 'screens/terms-of-use/terms-of-use';
import Confirmation from 'screens/confirmation/confirmation';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { appStateAtom } from 'state/app.atom';
import ServerError from 'screens/server-error/server-error';
import { ValuationsOverview } from 'screens/valuations-overview/valuations-overview';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Login from 'screens/login/login';
import UserListDetail from 'screens/user-list-detail/user-list-detail';
import { UserList } from 'screens/user-list/user-list';

function App() {
  const [searchParams] = useSearchParams();
  const setWizardData = useSetRecoilState(appStateAtom);

  useEffect(() => {
    let blz = searchParams.get('blz') || '38888';
    const branch = process.env.REACT_APP_BRANCH;
    return setWizardData((state) => ({ ...state, blz: state.blz ?? blz, branch }));
  }, [setWizardData, searchParams]);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          {/* <!-- ONE TRUST --> */}
          <script
            src='https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
            data-document-language='true'
            type='text/javascript'
            charSet='UTF-8'
            data-domain-script='28dd8348-296d-439c-a0af-86ca639ca94f'
          ></script>
          {/* Conditionally load GTM upon OneTrust cookie acceptance */}
          <script type='text/javascript'>
            {`function OptanonWrapper() {
              const isGTMLoaded = window.dataLayer.find(element => element['gtm.start']);
              const isAlertBoxClosed = !!document.cookie.split(';').find(cookie=> cookie.includes('OptanonAlertBoxClosed'));
              if(isAlertBoxClosed && !isGTMLoaded){
                console.log('GTM Loaded')
                let myScript = document.createElement("script");
                  myScript.innerHTML = (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','GTM-KCNMWL7');
                  document.body.appendChild(myScript);
              }
            }`}
          </script>
          {/* window.OneTrust.OnConsentChanged((e) => {
                if (e?.detail?.includes("C0002") || e?.detail?.includes("C0004")) {
                  let myScript = document.createElement("script");
                  myScript.innerHTML = (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','GTM-KCNMWL7');
                  document.body.appendChild(myScript);
                }
              }); */}
          <script src={`https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPCHA_KEY}`}></script>
        </Helmet>
        <RbiHeader></RbiHeader>
        <Routes>
          <Route element={<Onboarding />} path='/' />
          <Route element={<Wizard />} path='wizard' />
          <Route element={<TermsOfUse />} path='terms-of-use' />
          <Route element={<Confirmation />} path='confirmation' />
          <Route element={<ServerError />} path='server-error' />
          <Route element={<ValuationsOverview />} path='valuations' />
          <Route element={<Login />} path='admin' />
          <Route element={<UserList />} path='admin/users'></Route>
          <Route element={<UserListDetail />} path='admin/users/:userId' />
        </Routes>
        <RbiFooter></RbiFooter>
      </HelmetProvider>
    </>
  );
}

export default App;
