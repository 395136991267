import { RbiButton, RbiError, RbiInput, RbiSelect } from 'components';
import { WizardStepsTypes } from 'enums';
import { PlotDetails } from 'models';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useRecoilState } from 'recoil';
import { appStateAtom } from 'state';
import { usageOptions } from 'utils';

export const PlotDetailsForm: FC = () => {
  const [wizardData, setWizardData] = useRecoilState(appStateAtom);
  const defaultValues = { ...wizardData.propertyDetails?.plotDetails };
  const { register, getValues, formState } = useForm<PlotDetails>({ mode: 'onChange', defaultValues });
  const navigateForm = (nextStep: WizardStepsTypes) => {
    setWizardData((state) => ({ ...state, propertyDetails: { plotDetails: { ...getValues() } }, currentStep: nextStep }));
  };

  return (
    <>
      {/* PLOT AREA */}
      <RbiInput
        register={register('plotArea', { required: true, valueAsNumber: true, max: 3000, min: 0 })}
        hasErrors={!!formState.errors.plotArea}
        placeholder='Grundstückfläche'
        value={getValues('plotArea') || undefined}
        metric='m2'
        cssClasses='mt-4'
        type={'number'}
      ></RbiInput>
      {!!formState.errors.plotArea && <RbiError>Bitte geben Sie eine Grundstücksfläche von maximal 3000 m² als ganze Zahl an.</RbiError>}
      {/* END PLOT AREA */}

      {/* CONDITION */}
      <p className='mt-8 pb-2 text-sm'>Widmung</p>
      <RbiSelect register={register('usage', { required: true, valueAsNumber: true })} options={usageOptions}></RbiSelect>
      {/* END CONDITION */}

      <div className='mt-10 flex justify-around'>
        <RbiButton secondary onClick={() => navigateForm(WizardStepsTypes.PropertySelection)}>
          ZURÜCK
        </RbiButton>
        <div className='w-20'></div>
        <RbiButton
          disabled={!formState.isValid}
          onClick={() => {
            return navigateForm(WizardStepsTypes.Overview);
          }}
        >
          WEITER
        </RbiButton>
      </div>
    </>
  );
};
