import { FC } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down.svg';
import cx from 'classnames';

interface RbiSelectProps {
  options: [string, string][];
  register: UseFormRegisterReturn;
  cssClasses?: string;
}

export const RbiSelect: FC<RbiSelectProps> = ({ register, options, cssClasses }) => {
  return (
    <div className='relative h-12'>
      <ArrowDown className='absolute right-3 top-3 z-10 fill-light-grey'></ArrowDown>
      <select
        defaultValue={''}
        className={cx(`absolute h-12 w-full cursor-pointer appearance-none p-3 text-sm shadow-sm
         outline-none ${cssClasses}`)}
        {...register}
      >
        {options.map(([key, value], index) => (
          <option disabled={index === 0} key={index} value={key}>
            {value}
          </option>
        ))}
      </select>
    </div>
  );
};
