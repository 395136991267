import axios from 'axios';
import { RbiHeading, RbiButton } from 'components';
import { PropertyTypes, WizardStepsTypes } from 'enums';
import { HttpErrorBody } from 'models';
import { RecaptchaResponse } from 'models/recaptcha.model';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { appStateAtom, isUserLoggedInSelector } from 'state';
import { propertyOptions, conditionToString, equipmentsToString, usageToString, isNil, apiUrls } from 'utils';
import { RECAPTCHA_THRESHOLD, WAITING_TIME_VALUATION } from '../contact-data/contact-data';
import LoadingModal from '../contact-data/loading-modal';
import { OverviewTable } from './overview-table';

const Overview: FC = () => {
  const navigate = useNavigate();
  useEffect(() => window.scrollTo(0, 0), []);
  const isUserLoggedIn = useRecoilValue(isUserLoggedInSelector);
  const [wizardData, setWizardData] = useRecoilState(appStateAtom);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [, type, typeIcon] = propertyOptions().find(([type]) => type === wizardData.propertyType) || [];
  let placeData: [string, string][] | undefined;
  if (wizardData.placeDetails) {
    const { streetName, houseNumber, postalCode, city } = wizardData.placeDetails;
    placeData = [
      ['Straße', streetName],
      ['Hausnummer', houseNumber],
      ['Postleitzahl', postalCode],
      ['Ort', city],
    ];
  }
  let propertyData: [string, string][] | undefined;
  switch (wizardData.propertyType) {
    case PropertyTypes.Apartment:
      if (wizardData.propertyDetails?.apartmentDetails) {
        const { area, floor, condition, constructionYear, equipment } = wizardData.propertyDetails.apartmentDetails;
        propertyData = [
          ['Wohnfläche', `${area}`],
          ['Etage', `${floor}`],
          ['Zustand', conditionToString(condition)],
          ['Baujahr', `${constructionYear}`],
          ['Ausstattung', equipmentsToString(equipment)],
        ];
      }
      break;
    case PropertyTypes.Duplex:
    case PropertyTypes.FamilyHouse:
    case PropertyTypes.TerracedHouse:
      if (wizardData.propertyDetails?.houseDetails) {
        const { area, plotArea, condition, constructionYear, equipment } = wizardData.propertyDetails.houseDetails;
        propertyData = [
          ['Wohnfläche', `${area}`],
          ['Grundstückfläche', `${plotArea}`],
          ['Zustand', conditionToString(condition)],
          ['Baujahr', `${constructionYear}`],
          ['Ausstattung', equipmentsToString(equipment)],
        ];
      }
      break;
    case PropertyTypes.Plot:
      if (wizardData.propertyDetails?.plotDetails) {
        const { plotArea, usage } = wizardData.propertyDetails.plotDetails;
        propertyData = [
          ['Grundstückfläche', `${plotArea}`],
          ['Widmung', usageToString(usage)],
        ];
      }
  }
  const wait = (callback: () => any) => setTimeout(callback, WAITING_TIME_VALUATION);
  const preventScrolling = (prevent = true) => (document.body.style.overflow = prevent ? 'hidden' : '');

  const submitForm = () => {
    if (!isUserLoggedIn) {
      setWizardData((state) => ({ ...state, currentStep: WizardStepsTypes.ContactData }));
    } else {
      const user = wizardData.user;
      setIsLoading(true);
      preventScrolling();
      setWizardData((state) => ({ ...state, user }));
      grecaptcha.ready(async () => {
        if (!process.env.REACT_APP_RECAPCHA_KEY) {
          setWizardData((state) => ({ ...state, httpError: { message: 'No reCaptcha key', status: '0' } }));
          navigate('/server-error');
        } else {
          try {
            const token = await grecaptcha.execute(process.env.REACT_APP_RECAPCHA_KEY, { action: 'submitValuation' });
            const recaptchaResponse = (await axios.post<RecaptchaResponse>(apiUrls.recaptcha, { token })).data;
            if (!recaptchaResponse.success) {
              setWizardData((state) => ({ ...state, httpError: { message: 'reCaptcha Failed', status: '201' } }));
              navigate('/server-error');
            }
            if (recaptchaResponse.score < RECAPTCHA_THRESHOLD) {
              navigate('/onboarding');
            }
            await axios.post(apiUrls.valuations, { ...wizardData, user });
            preventScrolling(false);
            setIsLoading(false);
            navigate({ pathname: '/valuations', search: `?id=${user?.id}` });
          } catch (error: any) {
            console.log(error);
            wait(() => {
              setIsLoading(false);
              preventScrolling(false);
              const httpError = error?.response?.data as HttpErrorBody;
              setWizardData((state) => ({ ...state, httpError }));
              navigate('/server-error');
            });
          }
        }
      });
    }
  };

  return (
    <div>
      {isLoading && <LoadingModal></LoadingModal>}
      <RbiHeading variant={4}>Bereit für den ImmoCheck</RbiHeading>
      <p className='mb-6 text-sm'>Bitte bestätigen Sie die Angaben zu Ihrer Wunschimmobilie.</p>
      {!isNil(placeData) && (
        <OverviewTable
          title='Immobilie'
          onEdit={() => setWizardData((state) => ({ ...state, currentStep: WizardStepsTypes.PropertySelection }))}
          typeIcon={typeIcon}
          type={`${type}`}
          data={placeData}
        ></OverviewTable>
      )}
      <br />
      <OverviewTable
        title='Größe & Zustand'
        onEdit={() => setWizardData((state) => ({ ...state, currentStep: WizardStepsTypes.PropertyDetails }))}
        data={propertyData}
      ></OverviewTable>
      <div className='my-8 flex justify-around'>
        <RbiButton secondary onClick={() => setWizardData((state) => ({ ...state, currentStep: WizardStepsTypes.PropertyDetails }))}>
          ZURÜCK
        </RbiButton>
        <div className='w-20'></div>
        <RbiButton disabled={false} onClick={submitForm}>
          WEITER
        </RbiButton>
      </div>
    </div>
  );
};

export default Overview;
