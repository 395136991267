import { ReactComponent as SearchHouse } from 'assets/icons/searchhouse.svg';
import { ReactComponent as SendCalculation } from 'assets/icons/sendcalculation.svg';
import { ReactComponent as ThumbsUp } from 'assets/icons/thumbsup.svg';

const iconClass = 'w-20 lg:w-24 mx-auto mb-5';

export const onboardingSteps = [
  {
    icon: <SearchHouse className={iconClass}></SearchHouse>,
    title: 'Angaben zum WohnTraum',
    description:
      'Sie haben Ihre Wunschimmobilie gefunden? Mit dem ImmoCheck der Raiffeisen-Bankengruppe Steiermark können Sie online den Schätzwert Ihrer Wunschimmobilie ermitteln - mit nur wenigen Angaben und völlig kostenlos. Denn große Entscheidungen trifft man am besten mit einem guten Gefühl.',
  },
  {
    icon: <SendCalculation className={iconClass}></SendCalculation>,
    title: 'Ermitteln des Schätzwerts',
    description:
      'Binnen weniger Sekunden erhalten Sie per E-Mail das Ergebnis Ihres ImmoChecks. Hierbei greifen wir auf aktuelle Marktdaten zurück und achten selbstverständlich auf die datenschutzkonforme Verarbeitung Ihrer Daten.',
  },
  {
    icon: <ThumbsUp className={iconClass}></ThumbsUp>,
    title: 'Den Überblick behalten',
    description:
      'Sie wollen Ihre vergangenen ImmoCheck Ergebnisse auf einen Blick sehen und Schätzwerte miteinander vergleichen? Nutzen Sie unsere einfache Übersicht um Ergebnisdokumente herunterzuladen oder Ihre Schätzwerte im Blick zu behalten!',
  },
];
