import { FC, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as Logo } from '../assets/img/new-logo.svg';
import { ReactComponent as Logout } from '../assets/icons/logout.svg';
import { isUserLoggedIn } from 'utils/auth-utils';

export const RbiHeader: FC = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const [isAdminRoute, setIsAdminRoute] = useState<boolean>();
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>();
  const logout = () => {
    localStorage.clear();
    navigate('/admin');
  };

  useEffect(() => {
    setIsAdminRoute(location.pathname.includes('admin'));
    setIsLoggedIn(isUserLoggedIn());
  }, [isLoggedIn, location]);

  return (
    <div className='bg-white'>
      <div className='container flex items-center justify-between py-3 px-3'>
        <Link to='/'>
          <Logo className='h-14'></Logo>
        </Link>
        {isAdminRoute && isLoggedIn && (
          <div className='flex cursor-pointer items-center' onClick={logout}>
            <Logout className='mr-2 h-6 w-6 rounded-full bg-yellow'></Logout> <span className='text-sm'>Logout</span>
          </div>
        )}
      </div>
    </div>
  );
};
