import { FC } from 'react';
import cx from 'classnames';

interface RbiHeadingProps {
  variant: number;
  cssClasses?: string;
}

export const RbiHeading: FC<RbiHeadingProps> = ({ variant, cssClasses, children }) => {
  return (
    <h3
      className={cx(`font-semibold text-dark-grey ${cssClasses}`, {
        'mb-10 text-base lg:mb-12 lg:text-lg': variant === 3, //h3
        'mb-4 text-lg': variant === 4, //h2
      })}
    >
      {children}
    </h3>
  );
};
