import { FC } from 'react';
import { Link } from 'react-router-dom';

export const enum LinkType {
  Internal,
  External,
  ExternalSmall,
  InternalSmall,
}

interface RbiLinkProps {
  url: string;
  variant?: LinkType;
  queryParams?: string | null;
  cssClasses?: string;
  onClick?: () => any;
}

export const RbiLink: FC<RbiLinkProps> = ({ url, children, variant = LinkType.Internal, queryParams, cssClasses, onClick }) => {
  const renderLink = () => {
    switch (variant) {
      case LinkType.Internal:
        return (
          <Link
            onClick={onClick}
            className={`${cssClasses} bg-yellow p-3 px-7 text-xs font-semibold text-dark-grey sm:h-14 sm:p-4 sm:px-12 sm:text-sm`}
            to={{ pathname: url, search: queryParams ?? '' }}
          >
            {children}
          </Link>
        );
      case LinkType.External:
        return (
          <a
            onClick={onClick}
            target='_blank'
            rel='noopener noreferrer'
            className={`${cssClasses} bg-yellow p-3 px-7 text-xs font-semibold text-dark-grey sm:p-4 sm:px-12 sm:text-sm`}
            href={url}
          >
            {children}
          </a>
        );
      case LinkType.ExternalSmall:
        return (
          <a
            onClick={onClick}
            target='_blank'
            rel='noopener noreferrer'
            className={`${cssClasses} font-semibold underline decoration-yellow decoration-4 underline-offset-2`}
            href={url}
          >
            {children}
          </a>
        );
      case LinkType.InternalSmall:
        return (
          <Link
            onClick={onClick}
            className={`${cssClasses} font-semibold underline decoration-yellow decoration-4 underline-offset-2`}
            to={{ pathname: url, search: queryParams ?? '' }}
          >
            {children}
          </Link>
        );
    }
  };
  return renderLink();
};
