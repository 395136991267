import { FC, useState } from 'react';
import { ReactComponent as Check } from 'assets/icons/check.svg';
import cx from 'classnames';

interface RbiCheckboxProps {
  isSelected?: boolean;
  onSelect: (selected: boolean) => any;
  cssClasses?: string;
}

export const RbiCheckbox: FC<RbiCheckboxProps> = ({ children, isSelected, cssClasses, onSelect }) => {
  const [selected, setSelected] = useState<boolean>(isSelected || false);

  return (
    <div className='flex items-center'>
      <div
        onClick={() => {
          setSelected(!selected);
          onSelect(!selected);
        }}
        className={cx(
          `${cssClasses} absolute h-6 w-6 cursor-pointer rounded-sm border-[0.5px] transition-all duration-200 hover:bg-light-yellow`,
          {
            'bg-white': !selected,
            'bg-yellow hover:bg-yellow': selected,
          },
        )}
      >
        {selected && <Check className='absolute right-1 w-4'></Check>}
      </div>
      <div className='ml-8 text-sm font-normal'>{children}</div>
    </div>
  );
};
