export const banks: Record<string, { mandant: string; bankUrl: string; bankName: string }> = {
  '38888': {
    mandant: '2133',
    bankUrl: 'https://www.raiffeisen.at/stmk/de/meine-bank/kundenservice/immocheck-kontakt.html',
    bankName: 'Raiffeisen-Bankengruppe Steiermark',
  },
  '38000': {
    mandant: '126',
    bankUrl: 'https://www.raiffeisen.at/stmk/rlb/de/meine-bank/kundenservice/immocheck-kontakt.html',
    bankName: 'Raiffeisen-Landesbank Steiermark',
  },
  '38001': {
    mandant: '435',
    bankUrl: 'https://www.raiffeisen.at/stmk/admont/de/meine-bank/kundenservice/immocheck-kontakt.html',
    bankName: 'Raiffeisenbank Admont',
  },
  '38023': {
    mandant: '436',
    bankUrl: 'https://www.raiffeisen.at/stmk/oststeiermark-nord/de/meine-bank/kundenservice/immocheck-kontakt.html',
    bankName: 'Raiffeisenbank Oststeiermark Nord',
  },
  '38043': {
    mandant: '438',
    bankUrl: 'https://www.raiffeisen.at/stmk/schilcherland/de/meine-bank/kundenservice/immocheck-kontakt.html',
    bankName: 'Raiffeisenbank Schilcherland',
  },
  '38056': {
    mandant: '439',
    bankUrl: 'https://www.raiffeisen.at/stmk/sued-weststeiermark/de/meine-bank/kundenservice/immocheck-kontakt.html',
    bankName: 'Raiffeisenbank Süd-Weststeiermark',
  },
  '38071': {
    mandant: '440',
    bankUrl: 'https://www.raiffeisen.at/stmk/region-fehring/de/meine-bank/kundenservice/immocheck-kontakt.html',
    bankName: 'Raiffeisenbank Region Fehring',
  },
  '38075': {
    mandant: '441',
    bankUrl: 'https://www.raiffeisen.at/stmk/mittleres-raabtal/de/meine-bank/kundenservice/immocheck-kontakt.html',
    bankName: 'Raiffeisenbank Mittleres Raabtal',
  },
  '38077': {
    mandant: '442',
    bankUrl: 'https://www.raiffeisen.at/stmk/region-fuerstenfeld/de/meine-bank/kundenservice/immocheck-kontakt.html',
    bankName: 'Raiffeisenbank Region Fürstenfeld',
  },
  '38094': {
    mandant: '443',
    bankUrl: 'https://www.raiffeisen.at/stmk/gamlitz/de/meine-bank/kundenservice/immocheck-kontakt.html',
    bankName: 'Raiffeisenbank Gamlitz',
  },
  '38102': {
    mandant: '444',
    bankUrl: 'https://www.raiffeisen.at/stmk/gleinstaetten-leutschach-gross-st-florian/de/meine-bank/kundenservice/immocheck-kontakt.html',
    bankName: 'Raiffeisenbank Gleinstätten-Leutschach-Groß St. Florian',
  },
  '38103': {
    mandant: '445',
    bankUrl: 'https://www.raiffeisen.at/stmk/region-gleisdorf-pischelsdorf/de/meine-bank/kundenservice/immocheck-kontakt.html',
    bankName: 'Raiffeisenbank Region Gleisdorf-Pischelsdorf',
  },
  '38111': {
    mandant: '447',
    bankUrl: 'https://www.raiffeisen.at/stmk/gratkorn/de/meine-bank/kundenservice/immocheck-kontakt.html',
    bankName: 'Raiffeisenbank Gratkorn',
  },
  '38113': {
    mandant: '449',
    bankUrl: 'https://www.raiffeisen.at/stmk/schladming-groebming/de/meine-bank/kundenservice/immocheck-kontakt.html',
    bankName: 'Raiffeisenbank Schladming-Gröbming',
  },
  '38128': {
    mandant: '452',
    bankUrl: 'https://www.raiffeisen.at/stmk/halbenrain/de/meine-bank/kundenservice/immocheck-kontakt.html',
    bankName: 'Raiffeisenbank Halbenrain-Tieschen',
  },
  '38129': {
    mandant: '453',
    bankUrl: 'https://www.raiffeisen.at/stmk/mariazellerland/de/meine-bank/kundenservice/immocheck-kontakt.html',
    bankName: 'Raiffeisenbank Mariazellerland',
  },
  '38133': {
    mandant: '454',
    bankUrl: 'https://www.raiffeisen.at/stmk/hausmannstaetten/de/meine-bank/kundenservice/immocheck-kontakt.html',
    bankName: 'Raiffeisenbank Hausmannstätten',
  },
  '38138': {
    mandant: '455',
    bankUrl: 'https://www.raiffeisen.at/stmk/gratwein-hitzendorf/de/meine-bank/kundenservice/immocheck-kontakt.html',
    bankName: 'Raiffeisenbank Gratwein-Hitzendorf',
  },
  '38151': {
    mandant: '456',
    bankUrl: 'https://www.raiffeisen.at/stmk/ilz/de/meine-bank/kundenservice/immocheck-kontakt.html',
    bankName: 'Raiffeisenbank Ilz-Großsteinbach-Riegersburg',
  },
  '38170': {
    mandant: '457',
    bankUrl: 'https://www.raiffeisen.at/stmk/kirchbach/de/meine-bank/kundenservice/immocheck-kontakt.html',
    bankName: 'Raiffeisenbank Heiligenkreuz-Kirchbach-St. Georgen',
  },
  '38186': {
    mandant: '458',
    bankUrl: 'https://www.raiffeisen.at/stmk/muerztal/de/meine-bank/kundenservice/immocheck-kontakt.html',
    bankName: 'Raiffeisenbank Mürztal',
  },
  '38187': {
    mandant: '459',
    bankUrl: 'https://www.raiffeisen.at/stmk/weiz-anger/de/meine-bank/kundenservice/immocheck-kontakt.html',
    bankName: 'Raiffeisenbank Weiz-Anger',
  },
  '38206': {
    mandant: '460',
    bankUrl: 'https://www.raiffeisen.at/stmk/leibnitz/de/meine-bank/kundenservice/immocheck-kontakt.html',
    bankName: 'Raiffeisenbank Leibnitz',
  },
  '38210': {
    mandant: '461',
    bankUrl: 'https://www.raiffeisen.at/stmk/lieboch/de/meine-bank/kundenservice/immocheck-kontakt.html',
    bankName: 'Raiffeisenbank Lieboch-Stainz',
  },
  '38215': {
    mandant: '462',
    bankUrl: 'https://www.raiffeisen.at/stmk/liezen/de/meine-bank/kundenservice/immocheck-kontakt.html',
    bankName: 'Raiffeisenbank Liezen-Rottenmann-Trieben',
  },
  '38227': {
    mandant: '464',
    bankUrl: 'https://www.raiffeisen.at/stmk/liesingtal-st-stefan/de/meine-bank/kundenservice/immocheck-kontakt.html',
    bankName: 'Raiffeisenbank Liesingtal-St. Stefan',
  },
  '38238': {
    mandant: '465',
    bankUrl: 'https://www.raiffeisen.at/stmk/murau/de/meine-bank/kundenservice/immocheck-kontakt.html',
    bankName: 'Raiffeisenbank Murau',
  },
  '38249': {
    mandant: '466',
    bankUrl: 'https://www.raiffeisen.at/stmk/steirisches-salzkammergut-oeblarn/de/meine-bank/kundenservice/immocheck-kontakt.html',
    bankName: 'Raiffeisenbank Steirisches Salzkammergut-Öblarn',
  },
  '38252': {
    mandant: '467',
    bankUrl: 'https://www.raiffeisen.at/stmk/nestelbach-eggersdorf/de/meine-bank/kundenservice/immocheck-kontakt.html',
    bankName: 'Raiffeisenbank Nestelbach-Eggersdorf',
  },
  '38282': {
    mandant: '468',
    bankUrl: 'https://www.raiffeisen.at/stmk/passail/de/meine-bank/kundenservice/immocheck-kontakt.html',
    bankName: 'Raiffeisenbank Passail',
  },
  '38312': {
    mandant: '470',
    bankUrl: 'https://www.raiffeisen.at/stmk/bad-radkersburg/de/meine-bank/kundenservice/immocheck-kontakt.html',
    bankName: 'Raiffeisenbank Bad Radkersburg-Klöch',
  },
  '38346': {
    mandant: '471',
    bankUrl: 'https://www.raiffeisen.at/stmk/aichfeld/de/meine-bank/kundenservice/immocheck-kontakt.html',
    bankName: 'Raiffeisenbank Aichfeld',
  },
  '38347': {
    mandant: '472',
    bankUrl: 'https://www.raiffeisen.at/stmk/turnau-st-lorenzen/de/meine-bank/kundenservice/immocheck-kontakt.html',
    bankName: 'Raiffeisenbank Turnau-St. Lorenzen',
  },
  '38367': {
    mandant: '473',
    bankUrl: 'https://www.raiffeisen.at/stmk/graz-st-peter/de/meine-bank/kundenservice/immocheck-kontakt.html',
    bankName: 'Raiffeisenbank Graz-St. Peter',
  },
  '38368': {
    mandant: '474',
    bankUrl: 'https://www.raiffeisen.at/stmk/zirbenland/de/meine-bank/kundenservice/immocheck-kontakt.html',
    bankName: 'Raiffeisenbank Zirbenland',
  },
  '38370': {
    mandant: '475',
    bankUrl: 'https://www.raiffeisen.at/stmk/mureck/de/meine-bank/kundenservice/immocheck-kontakt.html',
    bankName: 'Raiffeisenbank Mureck',
  },
  '38374': {
    mandant: '477',
    bankUrl: 'https://www.raiffeisen.at/stmk/st-stefan-rosental/de/meine-bank/kundenservice/immocheck-kontakt.html',
    bankName: 'Raiffeisenbank St. Stefan-Jagerberg-Wolfsberg',
  },
  '38377': {
    mandant: '478',
    bankUrl: 'https://www.raiffeisen.at/stmk/region-graz-nord/de/meine-bank/kundenservice/immocheck-kontakt.html',
    bankName: 'Raiffeisenbank Region Graz Nord',
  },
  '38402': {
    mandant: '479',
    bankUrl: 'https://www.raiffeisen.at/stmk/neumarkt-oberwoelz/de/meine-bank/kundenservice/immocheck-kontakt.html',
    bankName: 'Raiffeisenbank Neumarkt-Oberwölz',
  },
  '38403': {
    mandant: '480',
    bankUrl: 'https://www.raiffeisen.at/stmk/region-hartberg/de/meine-bank/kundenservice/immocheck-kontakt.html',
    bankName: 'Raiffeisenbank Region Hartberg',
  },
  '38420': {
    mandant: '482',
    bankUrl: 'https://www.raiffeisen.at/stmk/strass/de/meine-bank/kundenservice/immocheck-kontakt.html',
    bankName: 'Raiffeisenbank Straß-Spielfeld',
  },
  '38433': {
    mandant: '483',
    bankUrl: 'https://www.raiffeisen.at/stmk/stallhofen/de/meine-bank/kundenservice/immocheck-kontakt.html',
    bankName: 'Raiffeisenbank Stallhofen',
  },
  '38436': {
    mandant: '484',
    bankUrl: 'https://www.raiffeisen.at/stmk/straden/de/meine-bank/kundenservice/immocheck-kontakt.html',
    bankName: 'Raiffeisenbank Straden',
  },
  '38439': {
    mandant: '485',
    bankUrl: 'https://www.raiffeisen.at/stmk/graz-strassgang/de/meine-bank/kundenservice/immocheck-kontakt.html',
    bankName: 'Raiffeisenbank Graz-Strassgang',
  },
  '38460': {
    mandant: '486',
    bankUrl: 'https://www.raiffeisen.at/stmk/leoben-bruck/de/meine-bank/kundenservice/immocheck-kontakt.html',
    bankName: 'Raiffeisenbank Leoben-Bruck',
  },
  '38477': {
    mandant: '487',
    bankUrl: 'https://www.raiffeisen.at/stmk/region-graz-thalerhof/de/meine-bank/kundenservice/immocheck-kontakt.html',
    bankName: 'Raiffeisenbank Region Graz-Thalerhof',
  },
  '38487': {
    mandant: '488',
    bankUrl: 'https://www.raiffeisen.at/stmk/lipizzanerheimat/de/meine-bank/kundenservice/immocheck-kontakt.html',
    bankName: 'Raiffeisenbank Lipizzanerheimat',
  },
  '38497': {
    mandant: '489',
    bankUrl: 'https://www.raiffeisen.at/stmk/region-feldbach/de/meine-bank/kundenservice/immocheck-kontakt.html',
    bankName: 'Raiffeisenbank Region Feldbach',
  },
  '38499': {
    mandant: '490',
    bankUrl: 'https://www.raiffeisen.at/stmk/wildon-preding/de/meine-bank/kundenservice/immocheck-kontakt.html',
    bankName: 'Raiffeisenbank Wildon-Preding',
  },
};
