import axios from 'axios';
import { User } from 'models';
import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Cell, useTable } from 'react-table';
import { useRecoilState } from 'recoil';
import { appStateAtom } from 'state';
import { apiUrls, formatDateTime, propertyTypeToString, valuationToAddress } from 'utils';
import { getErliestValuationByUser, getLatestValuationByUser } from 'utils/valuation-utils';
import { ReactComponent as Download } from 'assets/icons/download.svg';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down.svg';
import { getAuthHeaders, isUserLoggedIn } from 'utils/auth-utils';
import NumberFormat from 'react-number-format';

interface UserListDetailProps {}
interface TableRows {
  date: string;
  project: string;
  value: number;
  address: string;
  id: string;
}

const UserListDetail: FC<UserListDetailProps> = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState<User>();
  const [wizardData, setWizardData] = useRecoilState(appStateAtom);

  useEffect(() => {
    if (!isUserLoggedIn()) {
      navigate('/admin');
    }
    if (!wizardData.users) {
      axios
        .get<User[]>(apiUrls.usersAdmin, { headers: getAuthHeaders() })
        .then(({ data: users }) => setWizardData((state) => ({ ...state, users })))
        .catch((error) => {
          console.log(error);
          navigate('/admin');
        });
    } else {
      setUser(wizardData.users.find((user) => user.id === userId));
    }
  }, [navigate, setWizardData, userId, wizardData.users]);

  const data =
    useMemo(
      () =>
        user?.valuations.map((valuation): TableRows => {
          return {
            date: formatDateTime(valuation.createdAt),
            project: propertyTypeToString(valuation.propertyType),
            value: valuation.averageValuation,
            address: valuationToAddress(valuation).join(', '),
            id: valuation.id,
          };
        }),
      [user?.valuations],
    ) ?? [];

  const columns = useMemo<{ Header: string; accessor: keyof TableRows }[]>(
    () => [
      { Header: 'Datum', accessor: 'date' },
      { Header: 'Projekt', accessor: 'project' },
      { Header: 'Schätzwert', accessor: 'value' },
      { Header: 'Adresse', accessor: 'address' },
      { Header: 'Bewertung', accessor: 'id' },
    ],
    [],
  );

  const tableInstance = useTable({ columns, data });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;
  const renderData = (cell: Cell<TableRows, any>) => {
    switch (cell.column.id) {
      case 'id':
        return (
          <a href={`${apiUrls.valuations}/${cell.value}/pdf`}>
            <Download className='mr-2 w-5'></Download>
          </a>
        );
      case 'value':
        return (
          <>
            <NumberFormat
              displayType='text'
              value={cell.value}
              thousandSeparator='.'
              decimalSeparator=','
              decimalScale={2}
              fixedDecimalScale={true}
            ></NumberFormat>{' '}
            EUR
          </>
        );

      default:
        return cell.render('Cell');
    }
  };

  return (
    <div className='mx-auto mt-6 min-h-[30rem] sm:w-[90vw] md:w-[90vw] lg:w-[80vw]'>
      <h1 className='mb-10 text-2xl font-light'>IMMOCHECK - BACKOFFICE TOOL</h1>
      <div className='mb-14 flex flex-col bg-white shadow-md'>
        <div className='flex border-b-[1px] border-lighter-grey p-4'>
          <ArrowDown className='mr-4 h-8 w-10 rotate-90 cursor-pointer fill-dark-grey' onClick={() => navigate('/admin/users')}></ArrowDown>
          <h2 className='text-2xl'>
            {user?.firstName} {user?.lastName}
          </h2>
        </div>
        {user && (
          <div className='grid grid-cols-3 gap-4 p-8 text-xs'>
            <div>
              Vorname: <span className='font-bold'>{user?.firstName}</span>
            </div>
            <div>
              Nachname: <span className='font-bold'>{user?.lastName}</span>
            </div>
            <div>
              Email: <span className='font-bold'>{user?.email}</span>
            </div>
            <div>
              E-Mail: <span className='font-bold'>{user?.email}</span>
            </div>
            <div>
              Erste Bewertung: <span className='font-bold'>{formatDateTime(getErliestValuationByUser(user).createdAt)}</span>
            </div>
            <div>
              Letzte Bewertung: <span className='font-bold'>{formatDateTime(getLatestValuationByUser(user).createdAt)}</span>
            </div>
            <div>
              Bewertungen ges: <span className='font-bold'>{user.valuations.length}</span>
            </div>
            <div>
              BLZ: <span className='font-bold'>{getLatestValuationByUser(user).blz}</span>
            </div>
            <div>
              Einwilligung Marketingzwecke: <span className='font-bold'>{user.consentedMarketingPurposes ? 'Ja' : 'Nein'}</span>
            </div>
          </div>
        )}
      </div>
      <div className='mb-20 bg-white shadow-md'>
        <table {...getTableProps()} className='w-full'>
          <thead className='h-14 bg-light-grey text-white'>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th className='px-4 text-left font-normal' {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr className='cursor-default px-4 hover:bg-dark-white' {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} className='h-12 px-4 text-sm'>
                        {renderData(cell)}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UserListDetail;
