export enum EquipmentTypes {
  Loggia,
  Terrace,
  Balcony,
  Cellar,
  Elevator,
  AtticLocation,
  PrivateGarden,
  Garage,
}
