import { FC, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { PropertyTypes } from 'enums/property-types.enum';
import { RbiHeading } from 'components';
import { appStateAtom } from 'state';
import { ApartmentDetailsForm } from './apartment-details-form';
import { HouseDetailsForm } from './house-detail-form';
import { PlotDetailsForm } from './plot-details-form';

const PropertyDetail: FC = () => {
  useEffect(() => window.scrollTo(0, 0), []);
  const wizardData = useRecoilValue(appStateAtom);
  const renderFormFields = () => {
    switch (wizardData.propertyType) {
      case PropertyTypes.Apartment:
        return <ApartmentDetailsForm />;
      case PropertyTypes.Duplex:
      case PropertyTypes.FamilyHouse:
      case PropertyTypes.TerracedHouse:
        return <HouseDetailsForm />;
      case PropertyTypes.Plot:
        return <PlotDetailsForm />;
    }
  };
  return (
    <>
      <RbiHeading variant={4}>Wie groß ist Ihr WohnTraum?</RbiHeading>
      <p className='mb-6 text-sm'>Machen Sie bitte möglichst genaue Angaben zu Ihrer Wunschimmobilie.</p>
      {renderFormFields()}
    </>
  );
};

export default PropertyDetail;
