import { FC } from 'react';
import { ReactComponent as Edit } from 'assets/icons/edit.svg';

interface OverviewTableProps {
  title: string;
  onEdit: () => any;
  typeIcon?: JSX.Element;
  type?: string;
  data?: [string, string][];
}

export const OverviewTable: FC<OverviewTableProps> = ({ title, onEdit, typeIcon, type, data }) => {
  return (
    <div className='bg-white'>
      <div className='flex items-center justify-between border-b-[2px] border-dark-white'>
        <h2 className='px-5 py-2 text-lg font-semibold'>{title}</h2>
        <Edit className='w-14 cursor-pointer bg-yellow p-3' onClick={onEdit}></Edit>
      </div>
      {type && (
        <div className='flex items-center px-5 pt-6'>
          <div className='inline-block border-2 border-yellow px-4 pt-4'>{typeIcon}</div>
          <span className='ml-5 text-lg font-semibold'>{type}</span>
        </div>
      )}
      <div className='p-5'>
        {data?.map(
          ([key, value], index) =>
            value && (
              <div key={index} className='mb-1 flex justify-between'>
                <div className='text-sm text-dark-grey'>{key}</div>
                <div className='pl-4 text-right text-sm font-bold sm:pl-24'>{value}</div>
              </div>
            ),
        )}
      </div>
    </div>
  );
};
