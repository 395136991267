import { WizardStepsTypes } from 'enums';
import { FC, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { appStateAtom } from 'state';
import { ContactData } from './contact-data/contact-data';
import Overview from './overview/overview';
import PropertyDetail from './property-detail/property-detail';
import PropertySelection from './property-selection/property-selection';
import WizardSteps from './wizard-steps';

const Wizard: FC = () => {
  useEffect(() => window.scrollTo(0, 0), []);
  const wizardData = useRecoilValue(appStateAtom);

  const renderStep = () => {
    switch (wizardData.currentStep) {
      case WizardStepsTypes.PropertySelection:
        return <PropertySelection />;
      case WizardStepsTypes.PropertyDetails:
        return <PropertyDetail />;
      case WizardStepsTypes.Overview:
        return <Overview />;
      case WizardStepsTypes.ContactData:
        return <ContactData />;
    }
  };

  return (
    <div className='flex justify-center'>
      <div className='mx-4 mt-6 bg-dark-white sm:mt-0 sm:w-[80vw] md:w-[70vw] lg:w-[50vw]'>
        <WizardSteps></WizardSteps>
        <div className='mb-6 mt-0 sm:mt-6 sm:mb-10'>{renderStep()}</div>
      </div>
    </div>
  );
};

export default Wizard;
