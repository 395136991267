import { FC, useEffect, useState } from 'react';
import { ReactComponent as Checkmark } from 'assets/icons/checkmark.svg';
import { ReactComponent as Info } from 'assets/icons/info.svg';
import { LinkType, RbiHeading, RbiLink } from 'components';
import { useRecoilState } from 'recoil';
import { appStateAtom } from 'state';
import { WizardStepsTypes } from 'enums';
import { banks } from 'utils/bank-data';
import { pushToDataLayer, TrackingObjectBuilder } from 'utils/tag-manager';

interface ConfirmationProps {}

const Confirmation: FC<ConfirmationProps> = () => {
  useEffect(() => window.scrollTo(0, 0), []);
  const [wizardData, setWizardData] = useRecoilState(appStateAtom);
  const [contactUrl, setContactUrl] = useState<string>('');
  useEffect(() => {
    if (wizardData.blz) {
      const bankUrl = banks[wizardData.blz]?.bankUrl ?? banks['38888'].bankUrl;
      setContactUrl(bankUrl);
    }
  }, [setContactUrl, wizardData.blz]);
  useEffect(() => {
    setWizardData((state) => ({ ...state, currentStep: WizardStepsTypes.PropertySelection }));
  }, [setWizardData]);

  useEffect(() => {
    pushToDataLayer(
      new TrackingObjectBuilder()
        .forFieldSubmit({ eventCategory: 'Valuation', eventAction: 'Success', eventLabel: 'Successful Submission', eventValue: 0 })
        .build(),
    );
  }, []);
  return (
    <div className='text-center'>
      <div className='mx-auto my-10 bg-yellow p-16 text-center sm:w-[70vw] lg:w-[50vw]'>
        <Checkmark className='mx-auto mb-6 w-20 fill-dark-grey'></Checkmark>
        <RbiHeading variant={4}>Ihr Bewertungsergebnis ist auf dem Weg</RbiHeading>
        <p className='text-sm'>
          Wir haben die von Ihnen angegebenen Objektdaten ausgewertet. In wenigen Sekunden erhalten Sie eine E-Mail an
          <span className='font-bold'> {wizardData.user?.email} </span> mit einem Link zum errechneten Schätzwert.
        </p>
      </div>
      <div className='mx-auto border-2 border-yellow p-4 sm:w-[70vw] lg:w-[50vw]'>
        <Info className='mx-auto mb-4 h-11 w-11 rounded-full bg-yellow fill-dark-grey p-2'></Info>
        <p className='text-sm text-dark-grey'>
          Bitte beachten Sie, dass die Anzahl der Immobilien-Bewertungen durch den ImmoCheck limitiert ist. Sie haben die Möglichkeit, 3 Bewertungen pro Tag
          bzw. 10 Bewertungen pro Monat durchzuführen.
        </p>
      </div>
      {/* <div className='mx-auto w-[90vw] p-4 sm:w-[60vw] lg:w-[40vw]'>
        <p className='m-4 text-sm font-bold'>Entscheidungen trifft man am besten mit einem guten Gefühl!</p>
        <p className='text-sm text-dark-grey'>
          Mit dem WohnTraumRechner der Raiffeisen Bausparkasse erhalten Sie die passende Finanzierungslösung für Ihren WohnTraum - auf
          Wunsch sogar mit einer vorläufigen Finanzierungszusage!
        </p>
      </div> */}
      <div className='mx-auto my-10 border-[1px] border-light-grey p-4 pb-10 text-center text-sm text-dark-grey sm:w-[70vw] lg:w-[50vw]'>
        <h4 className='mb-4 text-left font-bold'>Raiffeisen Immobilien bietet Ihnen umfangreiche Service-Leistungen!</h4>
        <p className='mb-10 text-left'>
          Benötigen Sie ein detailliertes Gutachten (nach dem LiegenschaftsbewertungsG) oder möchten Sie eine Immobilie bestmöglich verkaufen oder verlässliche
          Mieter/innen dafür finden? Brauchen Sie eine Fachauskunft rund um die Themen Vererben, Verkaufen, Versteigern, Vermieten? Raiffeisen Immobilien ist
          Markt- und Kompetenzführer. Wir unterstützen Sie gerne.
        </p>
        <RbiLink url='https://www.raiffeisen.at/stmk/de/meine-bank/kundenservice/immocheck-kontakt-immobilien.html' variant={LinkType.External}>
          ZUR KONTAKTSEITE
        </RbiLink>
      </div>
      <div className='mx-auto my-10 border-[1px] border-light-grey p-4 pb-10 text-center text-sm text-dark-grey sm:w-[70vw] lg:w-[50vw]'>
        <h4 className='mb-4 text-left font-bold'>Ihre Wunsch-Immobilie finanzieren</h4>
        <p className='mb-10 text-left'>
          Sie suchen die passende Finanzierung für den Bau eines Hauses, den Kauf einer Wohnung oder die Sanierung Ihres Eigenheimes? Wir sind für Sie da.
        </p>
        <RbiLink url={contactUrl} variant={LinkType.External}>
          ZUR KONTAKTSEITE
        </RbiLink>
      </div>
    </div>
  );
};

export default Confirmation;
