import { FC, useState } from 'react';
import cx from 'classnames';
import { Gender } from 'enums/gender.enum';

interface RbiToggleProps {
  option1: string;
  option2: string;
  onChange: (...event: any[]) => any;
  hasErrors: boolean;
  defaultValue: Gender;
}

export const RbiToggle: FC<RbiToggleProps> = ({ option1, option2, onChange, hasErrors, defaultValue }) => {
  const [option1Selected, setOption1Selected] = useState<boolean>(defaultValue === Gender.Female);
  const [option2Selected, setOption2Selected] = useState<boolean>(defaultValue === Gender.Male);
  const toggle = (option1Selected: boolean) => () => {
    onChange(option1Selected ? Gender.Female : Gender.Male);
    setOption1Selected(option1Selected);
    setOption2Selected(!option1Selected);
  };

  return (
    <div className='flex'>
      <button
        onClick={toggle(true)}
        className={cx('b w-full p-4 text-sm font-semibold transition-all duration-200', {
          'bg-yellow hover:bg-light-yellow': option1Selected,
          'bg-white hover:bg-lighter-grey': !option1Selected,
          'text-red': hasErrors,
        })}
      >
        {option1}
      </button>
      <button
        onClick={toggle(false)}
        className={cx('b w-full p-4 text-sm font-semibold transition-all duration-200', {
          'bg-yellow hover:bg-light-yellow': option2Selected,
          'bg-white hover:bg-lighter-grey': !option2Selected,
          'text-red': hasErrors,
        })}
      >
        {option2}
      </button>
    </div>
  );
};
