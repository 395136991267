import { FC, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { appStateAtom } from 'state';
import { ReactComponent as Warning } from 'assets/icons/warning.svg';
import { RbiHeading, RbiLink, LinkType } from 'components';
import { CustomException, WizardStepsTypes } from 'enums';
import { useNavigate } from 'react-router-dom';
import { banks } from 'utils/bank-data';
import { pushToDataLayer, TrackingObjectBuilder } from 'utils/tag-manager';

const ServerError: FC = () => {
  const navigate = useNavigate();
  useEffect(() => window.scrollTo(0, 0), []);
  const [title, setTitle] = useState<string>();
  const [subtitle, setSubtitle] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [callToAction, setCallToAction] = useState<{ url: string; variant: LinkType; text: string }>();
  const [wizardData, setWizardData] = useRecoilState(appStateAtom);
  const [contactUrl, setContactUrl] = useState<string>('');
  useEffect(() => {
    if (wizardData.blz) {
      const bankUrl = banks[wizardData.blz]?.bankUrl ?? banks['38888'].bankUrl;
      setContactUrl(bankUrl);
    }
  }, [setContactUrl, wizardData.blz]);
  console.log(wizardData.httpError);

  useEffect(() => {
    setWizardData((state) => ({ ...state, currentStep: WizardStepsTypes.PropertySelection }));
    switch (wizardData.httpError?.id) {
      case CustomException.ExceededDailyValuationQuota:
      case CustomException.ExceededMonthlyValuationQuota:
      case CustomException.IpAttempsExceeded:
        pushToDataLayer(
          new TrackingObjectBuilder()
            .forFieldSubmit({ eventCategory: 'Valuation', eventAction: 'Blocked', eventLabel: 'User Limit Exceeded', eventValue: 0 })
            .build(),
        );
        setTitle(`Sie haben das Limit überschritten!`);
        setSubtitle(
          'Bitte beachten Sie, dass die Anzahl der Immobilien-Bewertungen pro Nutzer durch den ImmoCheck limitiert ist. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.',
        );
        setDescription(
          'Bis dahin können Sie gerne unsere anderen Services nutzen oder direkt mit uns in Kontakt treten. Denn große Entscheidungen, trifft man am besten mit einem guten Gefühl!',
        );
        setCallToAction({ text: 'ZUR KONTAKTSEITE', url: contactUrl, variant: LinkType.External });
        break;

      case CustomException.EmailError:
      case CustomException.PdfError:
      default:
        pushToDataLayer(
          new TrackingObjectBuilder()
            .forFieldSubmit({ eventCategory: 'Valuation', eventAction: 'Error', eventLabel: 'General Submission Error', eventValue: 0 })
            .build(),
        );
        setTitle('Entschuldigung');
        setSubtitle('Bei Ihrer Bewertung ist leider etwas schiefgelaufen. Bitte versuchen Sie es erneut.');
        setCallToAction({ text: 'ZUR ONBOARDING SEITE', url: '/', variant: LinkType.Internal });
        break;
    }
  }, [contactUrl, navigate, setWizardData, wizardData.httpError?.id]);

  return (
    <>
      <div className='text-center'>
        <div className='mx-auto my-10 bg-yellow p-16 text-center sm:w-[70vw] lg:w-[50vw]'>
          <Warning className='mx-auto mb-6 w-20 fill-dark-grey'></Warning>
          <RbiHeading variant={4}>{title}</RbiHeading>
          <p className='text-sm'>{subtitle}</p>
        </div>
        <div className='mx-auto p-4 sm:w-[70vw] lg:w-[50vw]'>
          <p className='text-sm text-dark-grey'>{description}</p>
        </div>
        <div className='my-16'>
          <RbiLink
            url={callToAction?.url ?? ''}
            variant={callToAction?.variant}
            // onClick={() => trackLink(`${callToAction?.text}`, 'Server Error')}
          >
            {callToAction?.text}
          </RbiLink>
        </div>
      </div>
    </>
  );
};

export default ServerError;
