import { WizardStepsTypes, PropertyTypes, Gender } from 'enums';
import { AppState } from 'models';
import { apartmentDetailsDefault, houseDetailsDefault, plotDetailsDefault } from './default-properties';

export const productionDefault: AppState = {
  mapError: false,
  currentStep: WizardStepsTypes.PropertySelection,
};

export const developmentDefault: AppState = {
  propertyType: PropertyTypes.Apartment,
  placeDetails: {
    city: 'Wien',
    houseNumber: '3',
    lat: 48.1779901,
    lng: 16.3755185,
    postalCode: '1100',
    streetName: 'Keplerplatz',
  },
  propertyDetails: {
    apartmentDetails: apartmentDetailsDefault,
    // houseDetails: houseDetailsDefault,
    // plotDetails: plotDetailsDefault,
  },
  user: {
    firstName: 'Juan',
    lastName: 'haha',
    gender: Gender.Male,
    email: 'juan.herrera@viable.at',
    consentedTermsOfUse: true,
    consentedMarketingPurposes: true,
    valuationsLeftThisMonth: 10,
    valuationsLeftToday: 3,
    valuations: [],
  },
  mapError: false,
  currentStep: WizardStepsTypes.PropertyDetails,
  // httpError: { status: 403, id: 0, message: 'The daily quota for valuations has been exceeded' },
};
