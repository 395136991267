import axios from 'axios';
import { RbiButton, RbiError, RbiInput } from 'components';
import { FC, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiUrls } from 'utils';
import { clearLocalStorage, saveToken } from 'utils/auth-utils';

interface LoginProps {}

const Login: FC<LoginProps> = () => {
  const navigate = useNavigate();
  const [areCredentialsIncorrect, setAreCredentialsIncorrect] = useState<boolean>();
  const usernameEl = useRef<HTMLInputElement>(null);
  const passwordEl = useRef<HTMLInputElement>(null);
  const login = async () => {
    setAreCredentialsIncorrect(false);
    const username = usernameEl.current?.value;
    const password = passwordEl.current?.value;
    try {
      const { data } = await axios.post(apiUrls.login, { username, password });
      saveToken(data.access_token);
      navigate('/admin/users');
    } catch (error) {
      console.log(error);
      clearLocalStorage();
      setAreCredentialsIncorrect(true);
    }
  };
  return (
    <div>
      <div>
        <div className='relative flex h-[50vh] flex-col items-start justify-center py-20 px-10 text-white before:absolute before:top-0 before:left-0 before:-z-10 before:h-full before:w-full before:bg-[url("/src/assets/img/hero.jpg")] before:bg-cover before:bg-center before:bg-no-repeat before:brightness-75 sm:h-[50vh] lg:h-[80vh] lg:px-60'>
          <div className='md: md: mx-auto h-[50vh] w-full bg-dark-white px-10 py-6 text-center shadow-2xl sm:w-[80vw] md:w-[70vw] md:px-20 md:py-12 lg:w-[40vw]'>
            <h1 className='text-2xl font-bold text-dark-grey'>LOGIN</h1>
            <RbiInput reference={usernameEl} placeholder='Benutzername' cssClasses='mt-8 text-dark-grey'></RbiInput>
            <RbiInput reference={passwordEl} type='password' placeholder='Passwort' cssClasses='my-4 text-dark-grey'></RbiInput>
            {areCredentialsIncorrect && <RbiError>Benutzername und/oder Passwort inkorrekt.</RbiError>}
            <RbiButton onClick={login} cssClass='mt-4'>
              LOGIN
            </RbiButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
