import { ReactComponent as Apartment } from 'assets/icons/ic_wohnungskauf.svg';
import { ReactComponent as FamilyHouse } from 'assets/icons/ic_hauskauf.svg';
import { ReactComponent as Duplex } from 'assets/icons/ic_doppelhaus.svg';
import { ReactComponent as TerracedHouse } from 'assets/icons/ic_reihenhaus.svg';
import { ReactComponent as Plot } from 'assets/icons/ic_grundstückskauf.svg';
import { PropertyTypes } from '../enums/property-types.enum';
import { EquipmentTypes, UsageTypes, ConditionTypes } from 'enums';

const iconClassname = 'mb-4 h-12';

export const propertyOptions = (cssClass = iconClassname): [PropertyTypes, string, JSX.Element][] => [
  [PropertyTypes.Apartment, 'Wohnung', <Apartment className={cssClass}></Apartment>],
  [PropertyTypes.FamilyHouse, 'Einfamilienhaus', <FamilyHouse className={cssClass}></FamilyHouse>],
  [PropertyTypes.Duplex, 'Doppelhaushälfte', <Duplex className={cssClass}></Duplex>],
  [PropertyTypes.TerracedHouse, 'Reihenhaus', <TerracedHouse className={cssClass}></TerracedHouse>],
  [PropertyTypes.Plot, 'Grundstück', <Plot className={cssClass}></Plot>],
];

export const conditionOptions: [string, string][] = [
  ['', 'Bitte Wählen'],
  [`${ConditionTypes.NotSanitized}`, 'Nicht saniert'],
  [`${ConditionTypes.Modernized}`, 'Modernisiert'],
  [`${ConditionTypes.PartiallyRenovated}`, 'Teilsaniert'],
  [`${ConditionTypes.New}`, 'Vollsaniert / Neu'],
];

export const equipmentOptions: [EquipmentTypes, string][] = [
  [EquipmentTypes.Loggia, 'Loggia'],
  [EquipmentTypes.Terrace, 'Terrasse'],
  [EquipmentTypes.Balcony, 'Balkon'],
  [EquipmentTypes.Cellar, 'Keller'],
  [EquipmentTypes.Elevator, 'Aufzug'],
  [EquipmentTypes.AtticLocation, 'Lage im Dachgeschoss'],
  [EquipmentTypes.PrivateGarden, 'Eigengarten'],
  [EquipmentTypes.Garage, 'Garage'],
];

export const apartmentEquipmentOptions: [EquipmentTypes, string][] = equipmentOptions.filter(([key]) => key !== EquipmentTypes.Garage);

export const houseEquipmentOptions: [EquipmentTypes, string][] = equipmentOptions.filter(
  ([key]) => ![EquipmentTypes.Elevator, EquipmentTypes.AtticLocation, EquipmentTypes.PrivateGarden].includes(key),
);

export const usageOptions: [string, string][] = [
  ['', 'Bitte Wählen'],
  [`${UsageTypes.ConstructionLand}`, 'Bauland'],
  [`${UsageTypes.GreenLand}`, 'Grünland'],
];
