import { cloneDeep } from 'lodash-es';
import { User } from 'models';
import { Valuation } from 'models/valuation.interface';

export const getLatestValuationByUser = (user: User): Valuation => {
  return cloneDeep(user.valuations).sort((a, b) => {
    return Date.parse(`${b.createdAt}`) - Date.parse(`${a.createdAt}`);
  })[0];
};
export const getErliestValuationByUser = (user: User): Valuation => {
  return cloneDeep(user.valuations).sort((a, b) => {
    return Date.parse(`${a.createdAt}`) - Date.parse(`${b.createdAt}`);
  })[0];
};
