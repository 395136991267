import { FC, Fragment, useEffect, useState } from 'react';
import { ReactComponent as Check } from 'assets/icons/check.svg';
import cx from 'classnames';
import { useRecoilValue } from 'recoil';
import { WizardStepsTypes } from 'enums';
import { appStateAtom, isUserLoggedInSelector } from 'state';

export const defaultSteps: [WizardStepsTypes, string][] = [
  [WizardStepsTypes.PropertySelection, 'Adressdaten'],
  [WizardStepsTypes.PropertyDetails, 'Größe und Zustand'],
  [WizardStepsTypes.Overview, 'Übersicht'],
  [WizardStepsTypes.ContactData, 'Kontaktdaten'],
];

const WizardSteps: FC = () => {
  const isUserLoggedIn = useRecoilValue(isUserLoggedInSelector);
  const wizardData = useRecoilValue(appStateAtom);
  const [steps, setSteps] = useState<[WizardStepsTypes, string][]>(defaultSteps);
  useEffect(() => {
    if (isUserLoggedIn) {
      setSteps((steps) => steps.slice(0, -1));
    }
  }, [isUserLoggedIn]);

  return (
    <div className='sticky top-0 z-30 h-20 items-center justify-between bg-dark-white text-center sm:flex'>
      {steps.map(([key, value]) => (
        <Fragment key={key}>
          <div className='mb-2 inline-block min-w-max'>
            <span
              className={cx('mr-1 rounded-2xl px-2 py-0.5 text-xs sm:mr-2 sm:px-2.5 sm:py-1', {
                'bg-yellow px-2': +key === +wizardData.currentStep,
                'bg-yellow px-1.5 py-0.5 sm:px-2': +key < +wizardData.currentStep,
                'bg-lighter-grey': +key > +wizardData.currentStep,
              })}
            >
              {+key + 1 <= +wizardData.currentStep ? <Check className='mb-0.5 inline-block h-4 w-2.5'></Check> : +key + 1}
            </span>

            <span
              className={cx('mr-3 text-xs sm:mr-0', {
                'font-semibold': +key <= +wizardData.currentStep,
                'mr-0': +key === steps.length - 1,
              })}
            >
              {value}
            </span>
          </div>
          {+key !== steps.length - 1 && (
            <span className='mx-2 -mt-1 hidden w-3 border-t border-light-grey sm:inline-block sm:w-full'></span>
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default WizardSteps;
