import { FC, useState } from 'react';
import MapDisplay from './map-display';
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import Marker from './map-marker';
import { useRecoilState } from 'recoil';
import cx from 'classnames';
import { RbiInput } from 'components';
import { appStateAtom } from 'state';
import { pushToDataLayer, TrackingObjectBuilder } from 'utils/tag-manager';

const defaultCoordinates: google.maps.LatLngLiteral = { lat: 48.20858275, lng: 16.37390625 };

const Map: FC = () => {
  const [wizardData, setWizardData] = useRecoilState(appStateAtom);
  const [center, setCenter] = useState<google.maps.LatLngLiteral>(defaultCoordinates);
  const [addressText, setAddressText] = useState<string>('');
  const [zoomLevel, setZoomLevel] = useState(10);
  const [markerPosition, setMarkerPosition] = useState<google.maps.LatLngLiteral>();
  const setMarker = (center: google.maps.LatLngLiteral) => {
    setZoomLevel(16);
    setCenter(center);
    setMarkerPosition(center);
  };
  const renderAutocomplete = (status: Status) => {
    // Checks if Places API has successfully loaded
    if (status === Status.SUCCESS) {
      const input = document.getElementById('autocompleteInput') as HTMLInputElement;
      const autocomplete = new google.maps.places.Autocomplete(input, {
        componentRestrictions: { country: 'at' },
        fields: ['address_components', 'geometry'],
        types: ['address'],
      });
      if (wizardData.placeDetails) {
        const { lat, lng, streetName, houseNumber, city } = wizardData.placeDetails;
        setMarker({ lat, lng });
        setAddressText(`${streetName} ${houseNumber}, ${city}`);
      }
      autocomplete.addListener('place_changed', () => {
        setWizardData((state) => ({ ...state, mapError: false }));
        const { geometry, address_components: address } = autocomplete.getPlace();
        const lat = geometry?.location?.lat() || defaultCoordinates.lat;
        const lng = geometry?.location?.lng() || defaultCoordinates.lng;
        setMarker({ lat, lng });
        const streetName = address?.filter(({ types }) => types.find((type) => type === 'route'))[0]?.long_name;
        const houseNumber = address?.filter(({ types }) => types.find((type) => type === 'street_number'))[0]?.long_name;
        const postalCode = address?.filter(({ types }) => types.find((type) => type === 'postal_code'))[0]?.long_name;
        const city = address?.filter(({ types }) => types.find((type) => type === 'locality'))[0]?.long_name || ' ';
        if (streetName && houseNumber && postalCode && city) {
          const place = { streetName, houseNumber, postalCode, city, lat, lng };
          pushToDataLayer(
            new TrackingObjectBuilder()
              .forFieldSubmit({ eventCategory: 'ProjectDetails', eventAction: 'Zipcode', eventLabel: `${postalCode}`, eventValue: 0 })
              .build(),
          );
          setWizardData((state) => ({ ...state, placeDetails: place, mapError: false }));
        } else {
          setWizardData((state) => ({ ...state, mapError: true }));
        }
      });
    }
  };
  return (
    <div>
      <p className='my-5 text-sm'>
        Geben Sie bitte die genaue Adresse Ihrer Wunschimmobilie in Österreich ein. <br /> <span className='font-bold'>Hinweis:</span> Sollte die Adresse Ihrer
        Wunschimmobilie nicht gefunden werden können, geben Sie bitte die nächstmögliche Hausnummer/Straße ein.
      </p>
      <RbiInput id='autocompleteInput' value={addressText} placeholder='Adresse (Straße, Hausnummer, und Ort)'></RbiInput>
      <Wrapper id='map' callback={renderAutocomplete} libraries={['places']} apiKey={process.env.REACT_APP_MAPS_KEY || ''}>
        <MapDisplay style={{ height: '20rem', marginTop: '1rem' }} streetViewControl={false} center={center} zoom={zoomLevel} fullscreenControl={false}>
          <Marker position={markerPosition} />
        </MapDisplay>
      </Wrapper>
      <div className={cx('mt-6 font-semibold text-red', { visible: wizardData.mapError, invisible: !wizardData.mapError })}>
        Bitte geben Sie eine Adresse mit Straße, Hausnummer und Ort an.
      </div>
    </div>
  );
};

export default Map;
