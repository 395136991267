import { ReactComponent as Info } from 'assets/icons/info.svg';
import { RbiInput, RbiError, RbiSelect, RbiCheckbox, RbiButton } from 'components';
import { WizardStepsTypes, EquipmentTypes } from 'enums';
import { ApartmentDetails } from 'models';
import { FC, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useRecoilState } from 'recoil';
import FloorInfoModal from 'screens/wizard/property-detail/floor-info-modal';
import { appStateAtom } from 'state';
import { conditionOptions, apartmentEquipmentOptions } from 'utils';
import ConditionInfoModal from './condition-info-modal';

export const ApartmentDetailsForm: FC = () => {
  const [wizardData, setWizardData] = useRecoilState(appStateAtom);
  const [isConditionModalOpen, setIsConditionModalOpen] = useState<boolean>(false);
  const [isFloorModalOpen, setIsFloorModalOpen] = useState<boolean>(false);
  const defaultValues = { ...wizardData.propertyDetails?.apartmentDetails };
  const { register, getValues, control, setValue, formState } = useForm<ApartmentDetails>({ mode: 'onChange', defaultValues });

  const navigateForm = (nextStep: WizardStepsTypes) => {
    setWizardData((state) => ({ ...state, propertyDetails: { apartmentDetails: { ...getValues() } }, currentStep: nextStep }));
  };
  const toggleConditionModal = (shouldOpen: boolean) => {
    setIsConditionModalOpen(shouldOpen);
    document.body.style.overflow = shouldOpen ? 'hidden' : '';
  };
  const toggleFloorModal = (shouldOpen: boolean) => {
    setIsFloorModalOpen(shouldOpen);
    document.body.style.overflow = shouldOpen ? 'hidden' : '';
  };
  const onCheckboxSelect = (value: EquipmentTypes) => (selected: boolean) => {
    if (selected) {
      setValue('equipment', [...(getValues('equipment') || []), parseInt(`${value}`)]);
    } else {
      const equipments = getValues('equipment').filter((equipment) => equipment !== value);
      setValue('equipment', equipments);
    }
  };

  return (
    <>
      {/* AREA */}
      <RbiInput
        register={register('area', {
          required: true,
          valueAsNumber: true,
          max: 500,
          min: 0,
        })}
        hasErrors={!!formState.errors.area}
        placeholder='Wohnfläche'
        value={getValues('area') || undefined}
        metric='m2'
        cssClasses='mb-4'
        type={'number'}
      ></RbiInput>

      {!!formState.errors.area && <RbiError>Bitte geben Sie eine Wohnfläche von maximal 500 m² als ganze Zahl an.</RbiError>}
      {/* END AREA */}
      {/* FLOOR */}
      <div className='-mb-4 flex justify-end'>
        <Info onClick={() => toggleFloorModal(true)} className='m-1 inline-block w-5 cursor-pointer fill-grey hover:fill-dark-grey'></Info>
      </div>
      {/* <div className='mt-10 flex justify-between'> */}
      <RbiInput
        register={register('floor', { required: true, min: 0, max: 50, valueAsNumber: true })}
        type='number'
        hasErrors={!!formState.errors.floor}
        value={getValues('floor') || undefined}
        placeholder='Etage'
        cssClasses='mt-4'
      ></RbiInput>
      {!!formState.errors.floor && <RbiError>Bitte geben Sie eine Etage von -3 bis 50 an.</RbiError>}
      {/* </div> */}
      {isFloorModalOpen && <FloorInfoModal onClose={() => toggleFloorModal(false)}></FloorInfoModal>}
      {/* FLOOR */}
      {/* CONDITION */}
      <div className='mt-10 flex justify-between'>
        <p className='pb-2 text-sm'>Zustand des Gebäudes</p>
        <Info onClick={() => toggleConditionModal(true)} className='m-1 inline-block w-5 cursor-pointer fill-grey hover:fill-dark-grey'></Info>
      </div>
      {isConditionModalOpen && <ConditionInfoModal onClose={() => toggleConditionModal(false)}></ConditionInfoModal>}
      <RbiSelect register={register('condition', { required: true, valueAsNumber: true })} options={conditionOptions}></RbiSelect>
      {/* END CONDITION */}
      {/* CONSTRUCTION YEAR */}
      <RbiInput
        register={register('constructionYear', { required: true, valueAsNumber: true, min: 1800, max: 9999 })}
        value={getValues('constructionYear') || undefined}
        cssClasses='mt-4'
        hasErrors={!!formState.errors.constructionYear}
        placeholder='Baujahr'
        type='number'
      ></RbiInput>
      {!!formState.errors.constructionYear && <RbiError>Bitte geben Sie eine Jahreszahl im Format JJJJ ab 1800 an.</RbiError>}
      {/* END CONSTRUCTION YEAR */}
      {/* EQUIPMENT */}
      <p className='mt-10 mb-6 text-sm'>Ausstattung</p>
      <div className='mb-12 grid grid-cols-2 gap-8'>
        <Controller
          control={control}
          name='equipment'
          render={({ field: { value } }) => (
            <>
              {apartmentEquipmentOptions.map(([id, text], index) => (
                <RbiCheckbox key={index} isSelected={value?.includes(id)} onSelect={onCheckboxSelect(id)}>
                  {text}
                </RbiCheckbox>
              ))}
            </>
          )}
        />
      </div>
      {/* END EQUIPMENT */}
      <div className='mt-4 flex justify-around'>
        <RbiButton secondary onClick={() => navigateForm(WizardStepsTypes.PropertySelection)}>
          ZURÜCK
        </RbiButton>
        <div className='w-20'></div>
        <RbiButton
          disabled={!formState.isValid}
          onClick={() => {
            return navigateForm(WizardStepsTypes.Overview);
          }}
        >
          WEITER
        </RbiButton>
      </div>
    </>
  );
};
