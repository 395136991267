import { FC } from 'react';
import cx from 'classnames';

interface RbiButtonProps {
  secondary?: boolean;
  disabled?: boolean;
  onClick?: () => any;
  cssClass?: string;
}

export const RbiButton: FC<RbiButtonProps> = ({ onClick, secondary, disabled, children, cssClass }) => {
  return (
    <button
      disabled={disabled}
      className={cx(
        `${cssClass} w-full max-w-xs p-3 px-7 text-xs font-semibold transition-colors duration-200 ease-in-out sm:p-4 sm:px-12 sm:text-sm`,
        {
          'bg-yellow text-dark-grey hover:bg-light-yellow': !secondary,
          'bg-light-grey text-white hover:bg-grey': secondary,
          'bg-light-yellow text-light-grey': disabled,
        },
      )}
      onClick={() => onClick?.()}
    >
      {children}
    </button>
  );
};
