import { RbiButton } from 'components';
import { FC } from 'react';
import { ReactComponent as Close } from 'assets/icons/close.svg';

interface ConditionalModalProps {
  onClose: () => any;
}

const ConditionInfoModal: FC<ConditionalModalProps> = ({ onClose }) => {
  return (
    <>
      <div className='fixed top-0 left-0 z-30 h-full w-full bg-grey opacity-80'></div>
      <div className='fixed inset-x-0 top-4 left-0 z-50 mx-auto bg-white py-12 px-5 text-center shadow-2xl sm:top-28 sm:w-[80vw] sm:px-24 md:w-[70vw] lg:w-[80vw]'>
        <Close
          onClick={onClose}
          className='absolute right-4 top-4 w-10 cursor-pointer rounded-3xl fill-light-grey p-3 hover:bg-grey hover:bg-opacity-10'
        ></Close>
        <h2 className='mb-4 text-lg font-thin'>ZUSTAND DES GEBÄUDES</h2>
        <p className='text-sm'>Um den Zustand des Gebäudes richtig einzuschätzen, beachten Sie bitte die folgenden Informationen:</p>
        <br />
        <p className='text-sm'>
          Bei einer <span className='font-bold'>Vollsanierung</span> wird das ganze Gebäude saniert und erneuert. Das inkludiert (1) die
          Erneuerung des Außenverputzes mit Erhöhung des Wärmeschutzes, (2) den Einbau/Austausch von Heizungsanlagen und (3) Installationen,
          (4) Einbau/Austausch von Badezimmern und (5) den Austausch von Fenstern.
        </p>
        <br />
        <p className='text-sm'>
          Eine <span className='font-bold'>Teilsanierung</span> liegt vor, wenn mindestens 2 der vorher genannten Maßnahmen umgesetzt worden
          sind.
        </p>
        <br />
        <p className='mb-4 text-sm sm:mb-16'>
          Eine <span className='font-bold'>Modernisierung</span> liegt vor, wenn die Bausubstanz zwar unberührt bleibt, aber kleinere
          Arbeiten und Verschönerungen durchgeführt wurden (bsp. neue Böden und Wandbemalung).
        </p>
        <RbiButton onClick={onClose}>OK</RbiButton>
      </div>
    </>
  );
};

export default ConditionInfoModal;
