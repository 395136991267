export const intl = new Intl.DateTimeFormat('de-AT', { year: 'numeric', month: '2-digit', day: '2-digit' });
export const intlWithTime = new Intl.DateTimeFormat('de-AT', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
});
export const formatDateTime = (date?: string) => intlWithTime.format(new Date(`${date}`));
