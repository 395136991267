import { isDevelopment, isStaging } from './environment';

const baseUrlApi = isDevelopment
  ? 'http://localhost:3000'
  : isStaging
  ? 'https://jqd2qt21n8.execute-api.eu-central-1.amazonaws.com/immocheck-dev'
  : '/api';

export const apiUrls = {
  valuations: `${baseUrlApi}/valuations`,
  recaptcha: `${baseUrlApi}/recaptcha`,
  users: `${baseUrlApi}/users`,
  excel: `${baseUrlApi}/admin/excel`,
  usersAdmin: `${baseUrlApi}/admin/users`,
  login: `${baseUrlApi}/admin/login`,
};
