import { FC, HTMLInputTypeAttribute, useEffect, useState } from 'react';
import cx from 'classnames';
import { UseFormRegisterReturn } from 'react-hook-form';

interface RbiInputProps {
  id: string;
  placeholder: string;
  value: any;
  metric: string;
  register: UseFormRegisterReturn;
  hasErrors: boolean;
  cssClasses: string;
  type?: HTMLInputTypeAttribute;
  reference: React.MutableRefObject<HTMLInputElement | null>;
}

export const RbiInput: FC<Partial<RbiInputProps>> = ({ id, placeholder, value, metric, register, hasErrors, cssClasses, type, reference }) => {
  const [isFocused, setIsFocused] = useState<boolean>();
  useEffect(() => {
    if (value) {
      setIsFocused(true);
    }
  }, [value]);

  return (
    <div className={`relative ${cssClasses}`}>
      <label
        className={cx('absolute left-4 top-3 z-10 cursor-text text-sm text-light-grey transition-all', {
          '-translate-y-2.5 text-[0.55rem]': isFocused,
          'text-red': hasErrors,
        })}
        htmlFor={id}
      >
        {placeholder}
      </label>
      <span className='absolute right-3 top-3 z-20 text-sm text-light-grey'>{metric}</span>
      {reference ? (
        <input
          id={id}
          {...register}
          ref={reference}
          onFocus={() => setIsFocused(true)}
          defaultValue={value || null}
          type={type || 'text'}
          onBlur={({ target }) => (!target.value ? setIsFocused(false) : null)}
          className={cx('hide-arrows relative h-12 w-full border-0 p-4 pt-4 pb-1 text-sm outline-none transition-all', {
            'shadow-sm': !isFocused,
            'shadow-md': isFocused,
            'text-red': hasErrors,
          })}
          placeholder=''
          onKeyDown={(event) => {
            if (type === 'number') {
              if ([',', '.', "'"].includes(event.key)) {
                event.preventDefault();
              }
            }
          }}
        />
      ) : (
        <input
          id={id}
          {...register}
          onFocus={() => setIsFocused(true)}
          defaultValue={value || null}
          type={type || 'text'}
          onBlur={({ target }) => (!target.value ? setIsFocused(false) : null)}
          className={cx('hide-arrows relative h-12 w-full border-0 p-4 pt-4 pb-1 text-sm outline-none transition-all', {
            'shadow-sm': !isFocused,
            'shadow-md': isFocused,
            'text-red': hasErrors,
          })}
          placeholder=''
          onKeyDown={(event) => {
            if (type === 'number') {
              if ([',', '.', "'"].includes(event.key)) {
                event.preventDefault();
              }
            }
          }}
        />
      )}
    </div>
  );
};
