import cx from 'classnames';
import { RbiHeading, RbiButton } from 'components';
import { PropertyTypes, WizardStepsTypes } from 'enums';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { appStateAtom } from 'state';
import { propertyOptions, propertyTypeToString } from 'utils';
import { pushToDataLayer, TrackingObjectBuilder } from 'utils/tag-manager';
import Map from './map/map';

const PropertySelection: FC = () => {
  const navigate = useNavigate();
  useEffect(() => window.scrollTo(0, 0), []);
  const [wizardData, setWizardData] = useRecoilState(appStateAtom);
  const selectProperty = (selectedProperty: PropertyTypes) => {
    console.log(selectedProperty);

    pushToDataLayer(
      new TrackingObjectBuilder()
        .forFieldSubmit({ eventCategory: 'ProjectDetails', eventAction: 'Type', eventLabel: propertyTypeToString(selectedProperty), eventValue: 0 })
        .build(),
    );
    return setWizardData((state) => ({ ...state, propertyType: selectedProperty }));
  };
  const isFormInvalid = wizardData.mapError || !wizardData.placeDetails || wizardData.propertyType === undefined;

  return (
    <>
      <RbiHeading variant={4}>Erzählen Sie uns mehr über Ihren WohnTraum.</RbiHeading>
      <p className='mb-6 text-sm'>Wählen Sie bitte die Art Ihrer Wunschimmobilie.</p>
      <div className='grid grid-cols-2 gap-4'>
        {propertyOptions().map(([propertyId, text, logo], index) => (
          <div
            key={index}
            className={cx('flex h-40 w-full cursor-pointer flex-col items-center justify-center bg-white', {
              'border-4 border-yellow': propertyId === wizardData.propertyType,
            })}
            onClick={() => {
              return selectProperty(propertyId);
            }}
          >
            {logo}
            <p className='text-sm font-bold'>{text}</p>
          </div>
        ))}
      </div>
      <Map></Map>
      <div className='mt-4 flex justify-around'>
        <RbiButton secondary onClick={() => navigate('/')}>
          ZURÜCK
        </RbiButton>
        <div className='w-20'></div>
        <RbiButton disabled={isFormInvalid} onClick={() => setWizardData((state) => ({ ...state, currentStep: WizardStepsTypes.PropertyDetails }))}>
          WEITER
        </RbiButton>
      </div>
    </>
  );
};

export default PropertySelection;
