import { Valuation } from 'models/valuation.interface';
import { FC } from 'react';
import { apiUrls, conditionToString, equipmentsToString, intl, isNil, propertyOptions, usageToString, valuationToAddress } from 'utils';
import NumberFormat from 'react-number-format';
import { ReactComponent as Download } from 'assets/icons/download.svg';
import { ReactComponent as PriceRange } from 'assets/img/price-range.svg';
import { pushToDataLayer, TrackingObjectBuilder } from 'utils/tag-manager';
interface ValuationCardProps {
  valuation: Valuation;
}

export const ValuationCard: FC<ValuationCardProps> = ({ valuation }) => {
  const getPropertyData = (valuation: Valuation) => propertyOptions('w-20').find(([optionType]) => optionType === valuation.propertyType);
  return (
    <>
      <div className='mt-5 flex flex-col bg-white lg:flex-row'>
        <div className='flex w-full items-center bg-yellow px-5 py-4 lg:w-72 lg:flex-col lg:justify-center lg:p-4'>
          {getPropertyData(valuation)?.[2]}
          <div className='ml-6 lg:ml-0 lg:text-center'>
            <h4 className='mt-2 font-semibold'>{getPropertyData(valuation)?.[1]}</h4>
            <h5 className='text-sm'>Bewertet am {intl.format(new Date(`${valuation.createdAt}`))}</h5>
          </div>
        </div>
        <div className='w-full p-5 pb-2 lg:p-10 lg:pb-1'>
          <div className='mb-10 grid grid-cols-1 gap-8 text-left lg:grid-cols-2'>
            {/* LEFT */}
            <div className='text-sm'>
              <p className='mb-2 text-sm'>Adresse:</p>
              <h2 className='text-2xl font-bold'>{valuationToAddress(valuation)[0]}</h2>
              <h2 className='mb-8 text-2xl font-bold'>{valuationToAddress(valuation)[1]}</h2>
              {!isNil(valuation?.area) && (
                <div className='flex justify-between'>
                  <p>Wohnfläche:</p>
                  <p className='font-semibold'>{valuation.area} m²</p>
                </div>
              )}
              {!isNil(valuation?.floor) && (
                <div className='flex justify-between'>
                  <p>Etage:</p>
                  <p className='font-semibold'>{valuation.floor}</p>
                </div>
              )}
              {!isNil(valuation?.plotArea) && (
                <div className='flex justify-between'>
                  <p>Grundstückfläche:</p>
                  <p className='font-semibold'>{valuation.plotArea} m²</p>
                </div>
              )}
              {!isNil(valuation?.condition) && (
                <div className='flex justify-between'>
                  <p>Bauzustand:</p>
                  <p className='font-semibold'>{conditionToString(valuation.condition)}</p>
                </div>
              )}
              {!isNil(valuation?.constructionYear) && (
                <div className='flex justify-between'>
                  <p>Baujahr:</p>
                  <p className='font-semibold'>{valuation.constructionYear}</p>
                </div>
              )}
              {!!valuation?.equipment?.length && (
                <div className='flex justify-between'>
                  <p>Ausstattung:</p>
                  <p className='pl-10 text-right font-semibold'>{equipmentsToString(valuation.equipment)}</p>
                </div>
              )}
              {!isNil(valuation?.usage) && (
                <div className='flex justify-between'>
                  <p>Widmung:</p>
                  <p className='pl-10 text-right font-semibold'>{usageToString(valuation.usage)}</p>
                </div>
              )}
            </div>
            {/* RIGHT */}
            <div className='flex border-t-[1px] border-lighter-grey pt-8 lg:border-0 lg:pt-0'>
              <PriceRange></PriceRange>
              <div className='ml-5 text-sm'>
                <div className='mb-10'>
                  <p>Obergrenze:</p>
                  <p className='text-base font-bold'>
                    <NumberFormat
                      displayType='text'
                      value={valuation.upperValuation}
                      thousandSeparator='.'
                      decimalSeparator=','
                      decimalScale={2}
                      fixedDecimalScale={true}
                    ></NumberFormat>{' '}
                    EUR
                  </p>
                </div>
                <div className='mb-10'>
                  <p>Richtwert::</p>
                  <p className='text-4xl font-bold'>
                    <NumberFormat
                      displayType='text'
                      value={valuation.averageValuation}
                      thousandSeparator='.'
                      decimalSeparator=','
                      decimalScale={2}
                      fixedDecimalScale={true}
                    ></NumberFormat>{' '}
                    EUR
                  </p>
                </div>
                <div>
                  <p>Untergrenze:</p>
                  <p className='text-base font-bold'>
                    <NumberFormat
                      displayType='text'
                      value={valuation.lowerValuation}
                      thousandSeparator='.'
                      decimalSeparator=','
                      decimalScale={2}
                      fixedDecimalScale={true}
                    ></NumberFormat>{' '}
                    EUR
                  </p>
                </div>
              </div>
            </div>
          </div>
          <a
            href={`${apiUrls.valuations}/${valuation.id}/pdf`}
            onClick={() =>
              pushToDataLayer(
                new TrackingObjectBuilder()
                  .forClick({
                    eventLabel: 'ImmoCheck Ergebnis heruntergeladen',
                    eventCategory: 'Button',
                  })
                  .build(),
              )
            }
          >
            <div className='flex h-20 cursor-pointer items-center justify-center border-t-[1px] border-lighter-grey bg-white font-bold hover:bg-dark-white'>
              <Download className='mr-2 w-5'></Download>
              <span>BEWERTUNG HERUNTERLADEN</span>
            </div>
          </a>
        </div>
      </div>
    </>
  );
};
