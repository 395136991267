import { FC, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { AGBUrl, dataProtectionUrl, disclaimerUrl, impressumUrl, raiffeisenAtUrl } from 'utils/static-urls';
import { ReactComponent as ArrowDown } from '../assets/icons/arrow-down.svg';
import { ReactComponent as LogoInverted } from '../assets/img/log.svg';

interface FooterProps {}

const footerLinks = [
  ['Impressum', impressumUrl],
  ['Datenschutz', dataProtectionUrl],
  ['AGB & Rechtliches', AGBUrl],
  ['Disclaimer', disclaimerUrl],
  ['Cookie-Präferenzen', disclaimerUrl],
  ['Raiffeisen-Bankengruppe Steiermark', raiffeisenAtUrl],
];

export const RbiFooter: FC<FooterProps> = () => {
  return (
    <div className='text-xs text-white'>
      <div className='bg-dark-grey'>
        <div className='container flex flex-col items-end justify-center py-6 px-5'>
          <div className='mb-4'>Ein Service der</div>
          <Link className='flex text-right' to='/'>
            <LogoInverted className='w-80'></LogoInverted>
          </Link>
        </div>
      </div>
      <div className='bg-grey'>
        <div className='container flex items-center justify-between py-5 px-5'>
          <div className='pr-5'>
            {footerLinks.map(([linkText, url], index) => (
              <Fragment key={index}>
                <ArrowDown className='inline-block h-4 -rotate-90 fill-white align-sub'></ArrowDown>
                {linkText === 'Cookie-Präferenzen' ? (
                  <button id='ot-footer-sdk-btn' className='ot-sdk-show-settings whitespace-nowrap leading-5 hover:underline'>
                    {linkText}
                  </button>
                ) : (
                  <a href={url} target='_blank' rel='noopener noreferrer' className='whitespace-nowrap leading-5 hover:underline'>
                    {linkText}
                  </a>
                )}
              </Fragment>
            ))}
          </div>
          <div>1.0.0 © Raiffeisen-Bankengruppe Steiermark {new Date().getFullYear()}</div>
        </div>
      </div>
    </div>
  );
};
