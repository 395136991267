import axios from 'axios';
import { User } from 'models';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { appStateAtom } from 'state';
import { apiUrls, formatDateTime } from 'utils';
import { useTable } from 'react-table';
import { useNavigate } from 'react-router-dom';
import { getLatestValuationByUser } from 'utils/valuation-utils';
import { RbiButton } from 'components';
import { clearLocalStorage, getAuthHeaders, isUserLoggedIn } from 'utils/auth-utils';

interface UserListProps {}

interface TableRows {
  lastEvaluationDate: string;
  blz: number;
  fullName: string;
  email: string;
  valuationsCount: number;
  id: string;
}

export const UserList: FC<UserListProps> = () => {
  const navigate = useNavigate();
  const filterInput = useRef<HTMLInputElement>(null);
  const [filterText, setFilterText] = useState<string>();
  const [wizardData, setWizardData] = useRecoilState(appStateAtom);
  useEffect(() => {
    if (!isUserLoggedIn()) {
      navigate('/admin');
    }
    if (!wizardData.users) {
      axios
        .get<User[]>(apiUrls.usersAdmin, { headers: getAuthHeaders() })
        .then(({ data: users }) => setWizardData((state) => ({ ...state, users })))
        .catch((error) => {
          console.log(error);
          clearLocalStorage();
          navigate('/admin');
        });
    }
  }, [navigate, setWizardData, wizardData.users]);

  let tableData =
    useMemo(() => {
      const rows = wizardData?.users
        ?.filter((user) => !!user.valuations?.length)
        .map((user): TableRows => {
          const { blz, createdAt } = getLatestValuationByUser(user);
          const fullName = `${user.firstName} ${user.lastName}`;
          const valuationsCount = user.valuations.length;
          return { lastEvaluationDate: `${createdAt}`, blz, fullName, email: user.email, valuationsCount, id: user.id ?? '' };
        });
      rows?.sort((a, b) => Date.parse(b.lastEvaluationDate) - Date.parse(a.lastEvaluationDate));
      const formattedRows = rows?.map((row) => ({ ...row, lastEvaluationDate: formatDateTime(row.lastEvaluationDate) }));
      return formattedRows;
    }, [wizardData?.users]) ?? [];

  if (filterText) {
    tableData = tableData.filter(
      (row) => row.email.includes(filterText) || row.fullName.includes(filterText) || `${row.blz}`.includes(filterText),
    );
  }

  const columns = useMemo<{ Header: string; accessor: keyof TableRows }[]>(
    () => [
      { Header: 'Datum', accessor: 'lastEvaluationDate' },
      { Header: 'BLZ', accessor: 'blz' },
      { Header: 'Vorname, Nachname', accessor: 'fullName' },
      { Header: 'E-Mail', accessor: 'email' },
      { Header: 'Anzahl Bewertungen', accessor: 'valuationsCount' },
    ],
    [],
  );
  const tableInstance = useTable({ columns, data: tableData });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;
  const downloadExcelFile = () => {
    axios({ url: apiUrls.excel, method: 'GET', responseType: 'blob', headers: { ...getAuthHeaders() } }).then((response) => {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(response.data);
      link.setAttribute('download', 'valuations.xlsx');
      document.body.appendChild(link);
      link.click();
    });
  };
  return (
    <div className='mx-auto mt-6 min-h-[30rem] sm:w-[90vw] md:w-[90vw] lg:w-[80vw]'>
      <h1 className='mb-10 text-2xl font-light'>IMMOCHECK - BACKOFFICE TOOL</h1>
      <div className='mb-20 bg-white shadow-md'>
        <div className='flex h-20 justify-between p-4'>
          <RbiButton onClick={downloadExcelFile} cssClass='w-32 sm:px-4 lg:w-60'>
            Herunterladen
          </RbiButton>
          <div>
            <input className='w-60 bg-dark-white p-4 text-sm' placeholder='BLZ, Name, E-Mail' type='text' ref={filterInput} />
            <RbiButton onClick={() => setFilterText(filterInput.current?.value)} cssClass='w-32 sm:px-4 w-40'>
              SUCHE
            </RbiButton>
          </div>
        </div>
        <table {...getTableProps()} className='w-full'>
          <thead className='h-14 bg-light-grey text-white'>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th className='px-4 text-left font-normal' {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className='cursor-pointer px-4 hover:bg-dark-white' onClick={() => navigate(row.original.id)}>
                  {row.cells.map((cell) => {
                    return (
                      <td className='h-12 px-4 text-sm' {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        {!tableData.length && <div className='p-8 text-sm'>Entschuldigung, zu Ihrem Suchbegriff gab es leider keine Ergebnisse.</div>}
      </div>
    </div>
  );
};
