import { ConditionTypes, EquipmentTypes, UsageTypes } from 'enums';
import { ApartmentDetails, HouseDetails, PlotDetails } from 'models';

export const apartmentDetailsDefault: ApartmentDetails = {
  area: 245,
  constructionYear: 1993,
  floor: 30,
  condition: ConditionTypes.New,
  equipment: [
    EquipmentTypes.Elevator,
    EquipmentTypes.PrivateGarden,
    EquipmentTypes.Terrace,
    EquipmentTypes.AtticLocation,
    EquipmentTypes.Balcony,
    EquipmentTypes.Cellar,
    EquipmentTypes.Garage,
    EquipmentTypes.Loggia,
  ],
};

export const houseDetailsDefault: HouseDetails = {
  area: 245,
  constructionYear: 1993,
  plotArea: 300,
  condition: ConditionTypes.NotSanitized,
  equipment: [EquipmentTypes.Terrace, EquipmentTypes.Garage, EquipmentTypes.Loggia, EquipmentTypes.Balcony, EquipmentTypes.Cellar],
};

export const plotDetailsDefault: PlotDetails = {
  plotArea: 300,
  usage: UsageTypes.ConstructionLand,
};
